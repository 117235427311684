import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from '@packages/hooks';
import { ToastContainer } from 'react-toastify';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';

import '@packages/ui/index.css';
import 'react-toastify/dist/ReactToastify.css';
import '@xyflow/react/dist/style.css';
import { FeaturesProvider } from '@packages/hooks/context';

const router = createRouter({ routeTree, defaultPreload: 'intent' });

const queryClient = new QueryClient();

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <FeaturesProvider>
          <RouterProvider router={router} />
          <ToastContainer />
        </FeaturesProvider>
      </AuthProvider>
    </QueryClientProvider>
  </StrictMode>,
);
