import { UiStore } from '@app/stores';
import { useShallow } from 'zustand/react/shallow';
import { useMemo, useState } from 'react';
import { useObjectivesStore } from '@app/stores/useObjectivesStore.ts';
import { ChevronDown, ChevronUp } from 'lucide-react';

export const ChangesMade = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { objectivesChangesMade } = UiStore(
    useShallow(state => ({
      objectivesChangesMade: state.objectivesChangesMade,
    })),
  );

  const { varOptions, modelOptions } = useObjectivesStore(
    useShallow(state => ({
      varOptions: state.varOptions,
      modelOptions: state.modelOptions,
    })),
  );

  function switchOpenState() {
    setIsOpen(prev => !prev);
  }

  // Memoize the flattened words array including both var and model options
  const wordsToShow = useMemo(() => {
    const modelWords = modelOptions.reduce<string[]>(
      (acc, item) => [...acc, item.key, ...item.options],
      [],
    );

    return [...varOptions, ...modelWords];
  }, [varOptions, modelOptions]);

  // Helper function to find keyword matches including phrases
  const findKeywordMatches = (text: string) => {
    const segments: { isKeyword: boolean; text: string }[] = [];
    let remainingText = text;

    while (remainingText.length > 0) {
      let matchFound = false;

      // Try to match the longest possible keyword first
      const sortedWords = [...wordsToShow].sort((a, b) => b.length - a.length);

      for (const word of sortedWords) {
        if (remainingText.toLowerCase().startsWith(word.toLowerCase())) {
          segments.push({
            isKeyword: true,
            text: remainingText.slice(0, word.length),
          });
          remainingText = remainingText.slice(word.length);
          matchFound = true;
          break;
        }
      }

      if (!matchFound) {
        // Find the next space or end of string
        const nextSpace = remainingText.indexOf(' ');
        const textSegment =
          nextSpace === -1
            ? remainingText
            : remainingText.slice(0, nextSpace + 1);

        segments.push({ isKeyword: false, text: textSegment });
        remainingText = remainingText.slice(textSegment.length);
      }
    }

    return segments;
  };

  return (
    <section className="w-full p-4 border rounded-md h-fit">
      <button
        className="flex w-full items-center justify-between cursor-pointer"
        onClick={switchOpenState}
      >
        <h3 className="text-sm text-gray-500">Changes made</h3>
        {isOpen ? (
          <ChevronUp className="w-4 h-4 text-gray-500" />
        ) : (
          <ChevronDown className="w-4 h-4 text-gray-500" />
        )}
      </button>

      {isOpen && (
        <div className="mt-2">
          {objectivesChangesMade.length === 0 ? (
            <section className="w-full h-full flex items-center justify-center">
              <span className="text-sm text-dark-1">No changes yet.</span>
            </section>
          ) : (
            <div className="space-y-2">
              {objectivesChangesMade.map((change, changeIndex) => (
                <p key={changeIndex}>
                  {findKeywordMatches(change).map((segment, segmentIndex) => (
                    <span
                      key={`${changeIndex}-${segmentIndex}`}
                      className={
                        segment.isKeyword
                          ? 'text-sm rounded-md bg-blue-50 text-blue-400'
                          : 'text-sm'
                      }
                    >
                      {segment.text}
                    </span>
                  ))}
                </p>
              ))}
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default ChangesMade;
