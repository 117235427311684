import { QueryClient, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { UiStore, useClientStore } from '@management/stores';
import { WSEvent } from '@management/types/socket.types.ts';
import { useAuth, useSocket } from '@packages/hooks';
// import { mergeObjects, separateMerged } from '@management/utils/MergeObjects.ts';
import { ClientClient, tables, UpdateClientPayload } from '@packages/api';
// import { Formatter } from '@management/utils/DataFormatter.ts';
import {
  defaultLightToastOptions,
  defaultToastErrorToastOptions,
} from '@management/configs.ts';
import { Formatter } from '@management/utils/DataFormatter.ts';
import { mergeCSVTablesToTables } from '@management/utils/MergeObjects.ts';
import { useShallow } from 'zustand/react/shallow';

export const useDatabaseManagement = () => {
  const { currentUser } = useAuth();
  const {
    tables,
    setTables,
    databaseInfo,
    isAllColumnsChecked,
    setIsAllColumnsChecked,
    setDatabaseInfo,
  } = useClientStore(
    useShallow(state => ({
      tables: state.tables,
      setTables: state.setTables,
      databaseInfo: state.databaseInfo,
      setDatabaseInfo: state.setDatabaseInfo,
      isAllColumnsChecked: state.isAllColumnsChecked,
      setIsAllColumnsChecked: state.setIsAllColumnsChecked,
    })),
  );

  const uiStore = UiStore();
  const {
    socket,
    setSocketExecLoading,
    socketExecLoading,
    chat_id,
  } = useSocket();

  const queryClient = new QueryClient();

  const clientAPI = new ClientClient();
  const formatter = new Formatter();

  socket?.on('form_json', (Event: WSEvent) => {
    if (Event.json) {
      uiStore.setDefaultForm(Event.json);
    }
  });

  socket?.on('tables', (Event: WSEvent) => {
    if (Event.tables) {
      setSocketExecLoading(false);

      if (typeof Event.tables === 'string') {
        const obj = JSON.parse(Event.tables);
        const formatedTables = formatter.webSocketTables(obj);

        setTables(formatedTables);

        return;
      }

      setTables(formatter.webSocketTables(Event.tables));
    }
  });

  const updateClient = useMutation(
    {
      mutationFn: (payload: UpdateClientPayload) =>
        clientAPI.update({
          id: currentUser!.owner,
          ...payload,
        }),
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: ['ownerInfo'],
        });

        toast.success('Client Database Updated!', defaultLightToastOptions);
        uiStore.setShowConnect(true);
      },
      onError: error => {
        toast.error(error.message, defaultToastErrorToastOptions);
      },
    },
    queryClient,
  );

  const handleConnectTables = () => {
    if (!socket) return;
    setTables([]);

    setSocketExecLoading(true);

    const form = databaseInfo.find(
      item => item.database_form_name === uiStore.currentDatabase,
    );

    if (!form) return;

    const databaseTablesPayload = formatter.databaseInfo({
      currentDatabase: uiStore.currentDatabase,
      form,
    });

    socket.emit('database_tables', databaseTablesPayload);
  };

  const sendSelectedColumns = () => {
    if (!socket) return;

    const currentDatabaseInfo = databaseInfo.find(
      database => database.database_form_name === uiStore.currentDatabase,
    );

    if (!currentDatabaseInfo) return;

    socket.emit('selected_columns', {
      chat_id,
      database: formatter.tablesToSelectedColumns({
        database_type: uiStore.currentDatabase,
        database_name: currentDatabaseInfo['database'],
        tables: tables,
      }),
    });

    updateClient.mutate({
      tables: tables,
    });
  };

  const checkColumns = ({
    tableName,
    itemName,
    isSelected,
  }: {
    tableName: string;
    itemName: string;
    isSelected: boolean;
  }) => {
    // const tables = tables;

    if (!isSelected) {
      if (isAllColumnsChecked) {
        setIsAllColumnsChecked(false);
      }
    }

    setTables(
      tables.map(table => {
        if (table.table_name.name === tableName) {
          // Check if the item exists in this table
          if (
            table.hasOwnProperty(itemName) &&
            typeof table[itemName] === 'object' &&
            table[itemName].hasOwnProperty('isSelected')
          ) {
            return {
              ...table,
              [itemName]: {
                ...table[itemName],
                isSelected: isSelected,
              },
            };
          }
        }
        return table;
      }),
    );
  };

  const checkAllColumns = (e: boolean | 'indeterminate') => {
    if (e === 'indeterminate') return;

    const tablesArr = tables;

    setTables(
      tablesArr.map(table => {
        const updatedColumns: tables = Object.keys(table).reduce(
          (acc, columnKey) => {
            if (columnKey === 'table_name') {
              acc[columnKey] = table[columnKey];
            } else {
              acc[columnKey] = {
                ...table[columnKey],
                isSelected: e,
              };
            }
            return acc;
          },
          {} as tables,
        );

        return updatedColumns;
      }),
    );
    setIsAllColumnsChecked(e);
  };

  const changeItemDescription = (
    tableName: string,
    itemName: string,
    value: string,
  ) => {
    const formatedTables = tables.map(
      (table): tables => {
        // Check if this is the correct table
        if (table.table_name.name === tableName) {
          const updatedTable = { ...table };
          if (
            itemName in updatedTable &&
            typeof updatedTable[itemName] === 'object'
          ) {
            updatedTable[itemName] = {
              ...updatedTable[itemName],
              description: value,
            };
          }

          return updatedTable;
        }

        // If it's not the table we're looking for, return it unchanged
        return table;
      },
    );

    setTables(formatedTables);
  };

  const handleLoadCsv = (csv: string) => {
    const tablesToFullFill = formatter.CsvToTables(tables, csv);

    const list = mergeCSVTablesToTables(tables, tablesToFullFill);

    setTables(list);

    toast.success('CSV loaded into the tables!', defaultLightToastOptions);
  };

  const updateDatabaseInfo = (data: Record<string, string | boolean>) => {
    const currentData = databaseInfo.filter(
      item => item.database_form_name !== uiStore.currentDatabase,
    );

    const payload = {
      id: currentUser!.owner,
      database: [
        ...currentData,
        {
          database_form_name: uiStore.currentDatabase,
          ...data,
        },
      ],
    };

    updateClient.mutate(payload);

    setDatabaseInfo(payload.database);
  };

  return {
    tables,
    setTables,
    databaseInfo,
    isAllColumnsChecked,
    setIsAllColumnsChecked,
    setDatabaseInfo,
    ...uiStore,
    updateClient,
    loading: socketExecLoading,
    updateDatabaseInfo,
    handleConnectTables,
    sendSelectedColumns,
    checkColumns,
    changeItemDescription,
    handleLoadCsv,
    checkAllColumns,
  };
};
