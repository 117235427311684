import { create } from 'zustand';
import { LineBlock, ModelOptions, ProcessedLine } from '@app/types';

type State = {
  varOptions: string[];
  modelOptions: ModelOptions;
  processedObjectives: ProcessedLine[];
  loadingKeyWords: boolean;
  text: string;
  selectedBlock: (LineBlock & { line: number }) | undefined;
  openVarModal: boolean;
};

type Actions = {
  setVarOptions: (options: string[]) => void;
  setModelOptions: (options: ModelOptions) => void;
  updateProcessedObjectives: (processedObjectives: ProcessedLine[]) => void;
  setText: (text: string) => void;
  resetStore: () => void;
  setLoadingKeyWords: (loading: boolean) => void;
  setSelectedBlock: (selectedBlock: LineBlock & { line: number }) => void;
  setOpenVarModal: (open: boolean) => void;
  updateModelOptions: (options: ModelOptions) => void;
};

export type ObjectivesStore = State & Actions;

const initialState: State = {
  varOptions: [],
  modelOptions: [],
  processedObjectives: [],
  loadingKeyWords: false,
  text: '',
  selectedBlock: undefined,
  openVarModal: false,
};

export const useObjectivesStore = create<ObjectivesStore>((set, get) => ({
  ...initialState,
  resetStore: () => set(initialState),
  setText: text => set({ text }),
  setVarOptions: options => set({ varOptions: options }),
  setModelOptions: options => set({ modelOptions: options }),
  updateProcessedObjectives: processedObjectives =>
    set({ processedObjectives }),
  setLoadingKeyWords: loading => set({ loadingKeyWords: loading }),
  setSelectedBlock: selectedBlock => set({ selectedBlock: selectedBlock }),
  setOpenVarModal: openVarModal => set({ openVarModal }),
  updateModelOptions: (options: { key: string; options: string[] }[]) =>
    set(() => {
      const currentModelOptions = get().modelOptions;

      const newModelOptions = options.reduce((acc, option) => {
        const existingOption = currentModelOptions.find(
          opt => opt.key === option.key,
        );

        if (existingOption) {
          return acc.map(opt =>
            opt.key === option.key ? { ...opt, options: option.options } : opt,
          );
        }

        return [...acc, option];
      }, currentModelOptions);

      return { modelOptions: newModelOptions };
    }),
}));
