import { InputList, KeyWordsList, LineBlock, ProcessedLine } from '@app/types';

export class ObjectivesProcessor {
  /*
  * transform:

  * example text with <var> highlight <var>

  * to

  * [
  * [{ type: 'normal', value: 'example text with' }, { type: 'highlight', value: 'highlight' }], ...
  * ]
  * */
  public processTextToSegments(text: string): ProcessedLine[] {
    return text.split('\n').map(line => {
      // Process var tags
      const varParts: LineBlock[] = line
        .split(/<var>/)
        .map((segment, index) => ({
          type: index % 2 === 0 ? 'normal' : 'var',
          value: segment.trim(),
        }));

      // Then process model tags in the normal segments
      const parts: LineBlock[] = varParts.flatMap(part => {
        if (part.type !== 'normal') return [part];

        return part.value.split(/<model>/).map((segment, index) => ({
          type: index % 2 === 0 ? 'normal' : 'model',
          value: segment.trim(),
        }));
      });

      return parts.filter(part => part.value !== '');
    });
  }

  public normalizeText(list: InputList[]): string {
    return list
      .map(item =>
        item.text
          .replace(/<var>(.*?)<var>/g, '$1')
          .replace(/<model>(.*?)<model>/g, '$1'),
      )
      .join('\n');
  }

  public processedLinesToVarText(blocks: { type: string; value: string }[]) {
    return blocks
      .map(block =>
        block.type === 'highlight' ? `<var>${block.value}<var>` : block.value,
      )
      .join('');
  }

  public varTextToNormalText(list: InputList[]): string {
    return list
      .map(item => item.text.replace(/<var>(.*?)<var>/g, '$1'))
      .join('\n');
  }

  public eventListToKeyWords(list: InputList[]): KeyWordsList {
    const result: KeyWordsList = [];

    list.forEach(item => {
      // Process var tags
      const varMatches = item.text.match(/<var>(.*?)<var>/);
      if (varMatches && item.var_options) {
        result.push({
          key: varMatches[1],
          list: [...item.var_options],
          type: 'var',
        });
      }

      // Process model tags
      const modelMatches = item.text.match(/<model>(.*?)<model>/);
      if (modelMatches && item.model_options) {
        result.push({
          key: modelMatches[1],
          list: [...item.model_options],
          type: 'model',
        });
      }
    });

    return result;
  }

  public processTextSegments(text: string): ProcessedLine {
    // First process var tags
    const processVar = (text: string) => {
      return text.split(/<var>/).map(
        (segment, index) =>
          ({
            type: index % 2 === 0 ? 'normal' : 'var',
            value: segment.trim(),
          } as const),
      );
    };

    // Then process model tags
    const processModel = (segments: LineBlock[]) => {
      return segments.flatMap(segment => {
        if (segment.type !== 'normal') return [segment];

        return segment.value.split(/<model>/).map(
          (subSegment, index) =>
            ({
              type: index % 2 === 0 ? 'normal' : 'model',
              value: subSegment.trim(),
            } as const),
        );
      });
    };

    return processModel(processVar(text)).filter(
      segment => segment.value !== '',
    );
  }
}
