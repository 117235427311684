import { Handle, NodeResizer, Position } from '@xyflow/react';
import { ShapesProps } from '@app/types';
import { cn } from '@app/lib/utils.ts';
import DiamondSvg from '@public/diamond.svg';

export const DiamondShape = ({
  selected,
  children,
  resized,
  onResizedChange,
  onClick,
}: ShapesProps) => {
  return (
    <button
      className={cn(
        'min-w-[122px] min-h-[122px] bg-transparent rounded-md flex overflow-hidden',
        resized ? 'w-full h-full' : 'w-[122px] h-[122px]',
      )}
      onClick={onClick}
    >
      <img
        src={DiamondSvg}
        alt="Diamond"
        className="absolute top-0 max-w-full aspect-auto"
      />
      <NodeResizer
        color="#ff0071"
        isVisible={selected}
        minWidth={122}
        minHeight={122}
        maxHeight={240}
        maxWidth={240}
        handleClassName="!w-2 !h-2"
        onResize={onResizedChange}
        keepAspectRatio
      />
      {children}
      <Handle type="target" position={Position.Left} id="left-target" />
      <Handle type="target" position={Position.Right} id="right-target" />
      <Handle type="target" position={Position.Top} id="top-target" />
      <Handle type="target" position={Position.Bottom} id="bottom-target" />
      <Handle type="source" position={Position.Left} id="left" />
      <Handle type="source" position={Position.Right} id="right" />
      <Handle type="source" position={Position.Top} id="top" />
      <Handle type="source" position={Position.Bottom} id="bottom" />
    </button>
  );
};
