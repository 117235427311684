import { createRootRoute, Outlet } from '@tanstack/react-router';
import { useAuth } from '@packages/hooks';
import { ErrorPage } from '@/pages/ErrorPage.tsx';
import Sidebar from '@ui/sidebar/Sidebar.tsx';

export const Route = createRootRoute({
  component: Layout,
  errorComponent: ErrorPage,
});

function Layout() {
  const { isAuthenticated, currentUser } = useAuth();

  if (!isAuthenticated) {
    // Here the only route that is accessible is the login page
    return (
      <main className="w-screen h-screen overflow-hidden">
        <Outlet />
      </main>
    );
  }

  return (
    <main className="w-screen h-screen overflow-hidden">
      <Sidebar username={currentUser!.name}>
        <Outlet />
      </Sidebar>
      {/*<Sidebar handleLogOut={logOut} open={open} handleClick={handleSwitchMenu}>*/}
      {/*  <SidebarContent isOpen={open} />*/}
      {/*</Sidebar>*/}
    </main>
  );
}
