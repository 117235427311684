import { flowChartStore, UiStore } from '@app/stores';
import { useShallow } from 'zustand/react/shallow';
import {
  ObjectivesStore,
  useObjectivesStore,
} from '@app/stores/useObjectivesStore.ts';
import { useCallback, useEffect } from 'react';
import { useSocket } from '@packages/hooks';
import {
  SOCKET_EVENTS,
  WS_STATUS,
  WS_STATUS_SUBJECTS,
  WSKeyPhraseEvent,
  WSObjectRewrittenEvent,
  WSStatusEvent,
} from '@packages/api';
import { toast } from 'react-toastify';
import { defaultToastErrorToastOptions } from '@packages/ui';
import { ModelOptions, ProcessedLine } from '@app/types';
import { ObjectivesProcessor } from '@app/utils/objectivesProcessor.ts';

interface UseObjectiveAreaProps {
  objectives?: string;
  analysis?: ProcessedLine[];
  _modelOptions?: ModelOptions;
}

const objectivesStoreSelector = (state: ObjectivesStore) => ({
  loadingKeyWords: state.loadingKeyWords,
  text: state.text,
  processedObjectives: state.processedObjectives,
  setLoadingKeyWords: state.setLoadingKeyWords,
  setText: state.setText,
  updateProcessedObjectives: state.updateProcessedObjectives,
  resetStore: state.resetStore,
  setVarOptions: state.setVarOptions,
  setModelOptions: state.setModelOptions,
  updateModelOptions: state.updateModelOptions,
  modelOptions: state.modelOptions,
});

export function useObjectivesArea({
  objectives,
  analysis,
  _modelOptions,
}: UseObjectiveAreaProps) {
  const { emitter, socket } = useSocket();
  const {
    loadingKeyWords,
    text,
    processedObjectives,
    setLoadingKeyWords,
    setText,
    updateProcessedObjectives,
    resetStore,
    setVarOptions,
    setModelOptions,
    updateModelOptions,
    modelOptions,
  } = useObjectivesStore(useShallow(objectivesStoreSelector));

  const { addObjectiveChange, setObjectivesChange } = UiStore(
    useShallow(state => ({
      addObjectiveChange: state.addObjectiveChange,
      setObjectivesChange: state.setObjectivesChange,
    })),
  );

  const { nodes, edges } = flowChartStore(
    useShallow(state => ({
      nodes: state.nodes,
      edges: state.edges,
    })),
  );

  const objectivesProcessor = new ObjectivesProcessor();

  useEffect(() => {
    if (text.length === 0 && objectives) {
      setText(objectives);
    }

    if (analysis) {
      updateProcessedObjectives(analysis);
    }

    if (_modelOptions) {
      setModelOptions(_modelOptions);
    }
  }, [setText, updateProcessedObjectives]);

  const handleObjectiveRewrittenEvent = useCallback(
    (e: WSObjectRewrittenEvent) => {
      const currentObjectives = useObjectivesStore.getState()
        .processedObjectives;

      // Now handles both var and model tags
      const processedText = objectivesProcessor.processTextSegments(e.text);

      if (currentObjectives.length <= e.position) return;

      const updatedObjectives = currentObjectives.map((arr, index) =>
        index === e.position ? processedText : arr,
      );

      if (e.model_options) {
        processedText.forEach(line => {
          if (line.type === 'model') {
            updateModelOptions([{ key: line.value, options: e.model_options }]);
          }
        });
      }

      updateProcessedObjectives(updatedObjectives);
      setLoadingKeyWords(false);
    },
    [],
  );

  const handlers = {
    [SOCKET_EVENTS.STATUS]: (e: WSStatusEvent) => {
      if (e.subject === WS_STATUS_SUBJECTS.KEY_PHRASE) {
        setLoadingKeyWords(true);
      }
    },

    [SOCKET_EVENTS.KEY_PHRASES]: (e: WSKeyPhraseEvent) => {
      setLoadingKeyWords(false);

      if (e.status === WS_STATUS.ERROR) {
        toast.error(
          'Problem analyzing your objectives.',
          defaultToastErrorToastOptions,
        );
        return;
      }

      const modelOptionsMap = e.list.reduce<
        { key: string; options: string[] }[]
      >((acc, item) => {
        // Skip if no model options
        if (!item.model_options) return acc;

        // Extract model tag value
        const modelMatch = item.text.match(/<model>(.*?)<model>/);
        if (modelMatch) {
          acc.push({
            key: modelMatch[1],
            options: item.model_options,
          });
        }

        return acc;
      }, []);

      setModelOptions(modelOptionsMap);

      const text = e.list.map(item => item.text).join('\n');

      updateProcessedObjectives(
        objectivesProcessor.processTextToSegments(text),
      );
    },

    [SOCKET_EVENTS.OBJECT_REWRITTEN]: (e: WSObjectRewrittenEvent) => {
      if (e.status === WS_STATUS.ERROR) {
        setLoadingKeyWords(false);
        toast.error(
          'Problem analyzing your objective selection.',
          defaultToastErrorToastOptions,
        );
        return;
      }

      handleObjectiveRewrittenEvent(e);
    },
  };

  useEffect(() => {
    if (!socket) return;

    const options = localStorage.getItem('options');

    if (options) {
      setVarOptions(JSON.parse(options));
    }

    Object.entries(handlers).forEach(([event, handler]) => {
      socket.on(event, handler);
    });

    return () => {
      Object.entries(handlers).forEach(([event, handler]) => {
        socket.off(event, handler);
      });

      // clean Objectives Store
      resetStore();

      setObjectivesChange([]);
    };
  }, [socket]);

  function emitObjectives(textAreaValue?: string) {
    emitter(SOCKET_EVENTS.FLOWCHART_OBJECTIVES, {
      objectives: textAreaValue ?? text,
      flow_chart_payload: {
        nodes,
        edges,
      },
    });
  }

  function redefine() {
    setText(objectives ?? '');
  }

  const handleAddSelectedKey = useCallback(
    ({
      currentWord,
      newWord,
      position,
    }: {
      currentWord: string;
      newWord: string;
      position: number;
    }) => {
      // Update objectives for both var and model changes
      const updatedObjectives = processedObjectives.map((arr, index) => {
        if (index === position) {
          return arr.map(block =>
            block.value === currentWord ? { ...block, value: newWord } : block,
          );
        }
        return arr;
      });

      // Get the block type that was changed
      const changedBlock = processedObjectives[position]?.find(
        block => block.value === currentWord,
      );

      // If block is a model, update the relation key/options keeping the options of the current selected to the new
      if (changedBlock?.type === 'model') {
        const currentModel = modelOptions.find(opt => opt.key === currentWord);

        const options = currentModel?.options
          ? [...new Set([...currentModel.options, currentWord])]
          : [currentWord];

        updateModelOptions([{ key: newWord, options }]);
      }

      // Only emit if it's a var change
      if (changedBlock?.type === 'var') {
        emitter(SOCKET_EVENTS.OPTION_SELECTED, {
          text: text.split('\n')[position] || '',
          option: newWord,
          position,
          timestamp: Date.now(),
        });
      }

      updateProcessedObjectives(updatedObjectives);
    },
    [processedObjectives],
  );

  return {
    addObjectiveChange,
    processedObjectives,
    emitObjectives,
    loadingKeyWords,
    text,
    setText,
    redefine,
    handleAddSelectedKey,
    modelOptions,
  };
}
