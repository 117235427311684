import { useEffect, useState } from 'react';
import { cn } from '@shadcn/lib/utils.ts';

interface AnimatedTextProps {
  text: string;
  className?: string;
}

export const AnimatedText = ({ text, className }: AnimatedTextProps) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTypingDone, setIsTypingDone] = useState(false);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText(prev => prev + text[currentIndex]);
        setCurrentIndex(curr => curr + 1);
      }, 80);

      return () => clearTimeout(timeout);
    } else {
      // Wait a bit before removing the cursor
      const finalTimeout = setTimeout(() => {
        setIsTypingDone(true);
      }, 200);

      return () => clearTimeout(finalTimeout);
    }
  }, [currentIndex]);

  return (
    <h1
      className={cn(
        'text-3xl font-bold text-dark-300 flex items-center',
        className,
      )}
    >
      {displayedText}
      <span
        className={cn(
          'inline-block w-0.5 h-6 ml-1 mb-0 bg-blue-400',
          isTypingDone ? 'hidden' : 'animate-blink',
        )}
      ></span>
    </h1>
  );
};
