import { Navigate, Outlet } from '@tanstack/react-router';
import { useAuth } from '@hooks/useAuth.ts';
import { useClientStore } from '@management/stores';
import { useShallow } from 'zustand/react/shallow';
import { ClientClient } from '@api/client';
import { useQuery } from '@tanstack/react-query';
import { ClientInfo } from '@api/types';
import { useEffect } from 'react';
import { FadeIconLoading } from '@ui/Loading';

export function ManagementLayout() {
  const { isAuthenticated, currentUser } = useAuth();

  const {
    setTables,
    setDatabaseInfo,
    setMemoryList,
    setFeatures,
  } = useClientStore(
    useShallow(state => ({
      setTables: state.setTables,
      setDatabaseInfo: state.setDatabaseInfo,
      setMemoryList: state.setMemoryList,
      setFeatures: state.setFeatures,
    })),
  );
  const clientAPI = new ClientClient();

  const { data, isLoading } = useQuery<ClientInfo>({
    queryKey: ['clientInfo', currentUser?.owner],
    queryFn: () => clientAPI.getClient(currentUser!.owner),
    refetchOnMount: false,
    retryOnMount: false,
  });

  useEffect(() => {
    if (!isAuthenticated) return;

    if (data) {
      if (data.tables.length > 0) {
        setTables(data.tables);
      }

      if (data.database.length > 0) {
        setDatabaseInfo(data.database);
      }

      if (data.features) {
        setFeatures(data.features);
      }

      if (data.memoryList?.length > 0) {
        setMemoryList(data.memoryList);
      }
    }
  }, [isLoading, data, isAuthenticated]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (isLoading) {
    return <FadeIconLoading text="Loading Team data" />;
  }

  return (
    <main className="w-full max-w-screen h-screen max-h-screen bg-neutral-100 px-28 pt-28 pb-10 flex items-center justify-center">
      <Outlet />
    </main>
  );
}
