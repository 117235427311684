import { useCallback, useMemo, useState } from 'react';
import { Search } from 'lucide-react';
import { cn } from '@packages/ui/shadcn/lib/utils.ts';
import { UiStore } from '@app/stores';
import { OptionBtn } from '@components/ObjectivesArea/OptionBtn.tsx';
import { addSelectedKeyFn } from '@app/types';
import { useObjectivesStore } from '@app/stores/useObjectivesStore.ts';
import { useShallow } from 'zustand/react/shallow';

interface VarOptsProps {
  handleAddSelectedKey: addSelectedKeyFn;
}

export const VarOptionsList = ({ handleAddSelectedKey }: VarOptsProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const addObjectiveChange = UiStore(state => state.addObjectiveChange);
  const {
    selectedBlock,
    openVarModal,
    setOpenVarModal,
    varOptions,
  } = useObjectivesStore(
    useShallow(state => ({
      selectedBlock: state.selectedBlock,
      openVarModal: state.openVarModal,
      setOpenVarModal: state.setOpenVarModal,
      varOptions: state.varOptions,
    })),
  );

  // const divRef = useOutsideClick<HTMLDivElement>(() => setOpenVarModal(false));

  const filteredOptions = useMemo(() => {
    return varOptions.filter(option =>
      option.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [varOptions, searchTerm]);

  const handleSelectKey = useCallback(
    (option: string) => {
      if (!selectedBlock) return;

      const { value: word, line } = selectedBlock;

      handleAddSelectedKey({
        currentWord: word,
        newWord: option,
        position: line,
      });

      addObjectiveChange(`The word ${word} was replaced with ${option}`);
      setOpenVarModal(false);
    },
    [selectedBlock, handleAddSelectedKey, addObjectiveChange, setOpenVarModal],
  );

  return (
    <div
      // ref={divRef}
      className={cn(
        'absolute right-0 top-2 p-2 w-fit max-w-64 max-h-[95%] h-[95%] rounded-md bg-white shadow-sm z-30 overflow-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]',
        'transition-all duration-200 ease-in-out',
        'flex flex-col gap-2',
        !openVarModal && 'hidden',
      )}
    >
      <div className="relative min-w-12 max-w-full flex gap-1">
        <input
          type="text"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder={selectedBlock?.value}
          className="w-full pl-6 py-1 text-xs border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <Search className="absolute left-2 top-1/2 -translate-y-1/2 w-3 h-3 text-gray-400" />
      </div>

      {filteredOptions.map(option => (
        <OptionBtn key={option} option={option} onClick={handleSelectKey} />
      ))}
    </div>
  );
};
