import { useLocation } from '@tanstack/react-router';

export function useContextLocation(user: string) {
  const location = useLocation();

  // console.debug('~ useContextLocation, useLocation hook return', location);

  const formatedLocation = location.pathname.replace('/', '');

  const formatedUserName = user.split('@');

  const paths: Record<string, string> = {
    dashboard: `${formatedUserName[0]}'s dashboard`,
    app: 'App Builder',
    commandInterface: 'Command Interface',
    management: 'Team Management',
    'management/database': 'Database Management',
    'management/capability': 'Capability Management',
    'management/memory': 'Memory Management',
  };

  return {
    currentLocation: paths[formatedLocation],
    ...location,
  };
}
