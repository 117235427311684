import { ReactElement } from 'react';
import airaLogo from '@public/aira-main-logo.png';

export interface Children {
  children: ReactElement | ReactElement[] | null;
}

export const Container = ({ children }: Children) => {
  return (
    <div className="max-w-lg w-full p-10 flex flex-col items-center justify-center gap-10 rounded-xl shadow-lg bg-white">
      <img src={airaLogo} alt="Aira Technologies" className="w-1/2" />
      {/*The vite compiler handlers this, just doing it for type safety*/}
      {children instanceof Array ? children.map(child => child) : children}
    </div>
  );
};
