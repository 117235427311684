import { useNavigate } from '@tanstack/react-router';
import { Button } from '@packages/ui/shadcn/ui/button.tsx';
import { PenIcon } from '@rangpt/components/ui/penIcon.tsx';
import { CheckIcon } from '@rangpt/components/ui/checkIcon.tsx';

const EditModeHeader = () => {
  const navigate = useNavigate();

  const confirmChanges = () => {
    navigate({
      to: '/commandInterface',
    });
  };

  return (
    <header className="col-start-1 col-end-3 row-span-1 flex justify-between border-b border-[#CBD5E1]">
      <section className="h-full p-2 flex items-center">
        <img src="/aira-logo.png" alt="Aira Technologies" className="h-8" />
      </section>
      <section className="h-15">
        <Button
          variant="ghost"
          className="h-full text-sm tracking-tight leading-3 font-normal py-1 rounded-none"
          // onClick={}
        >
          <span className="text-sm font-medium flex items-center gap-2 h-full">
            <PenIcon />
            Dashboard 1
          </span>
        </Button>
      </section>
      <section className="h-full py-2 pr-4 min-w-[12rem] flex justify-end items-center">
        <Button
          variant="ghost"
          className="text-sm h-9/10 tracking-tight leading-3 font-normal py-1 rounded-lg ring-1 ring-inset ring-gray-300 px-2 pr-3"
          onClick={confirmChanges}
        >
          <span className="text-sm font-medium flex items-center gap-2">
            <CheckIcon />
            Confirm
          </span>
        </Button>
      </section>
    </header>
  );
};

export { EditModeHeader };
