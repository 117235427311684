import { RoutinesStore, useRoutinesStore } from '@app/stores/routine.store.ts';
import { useMutation, useQuery } from '@tanstack/react-query';
import { RoutineClient, RoutinePayload } from '@packages/api';
import { v4 as uuidv4 } from 'uuid';
import { initialEdges, initialNodes } from '@app/lib/mockValues.ts';
import { flowChartStore } from '@app/stores';
import { FlowChartEdge, FlowChartNode } from '@app/types';
import { useShallow } from 'zustand/react/shallow';
import { useNavigate } from '@tanstack/react-router';
import { RoutinesList } from '@app/types/ui.types.ts';
import { useEffect } from 'react';
import { useCodeStore } from '@app/stores/code.store.ts';
import { queryClient } from '@app/utils/queryClient.ts';
import { toast } from 'react-toastify';
import { defaultToastErrorToastOptions } from '@packages/ui';

const routineStoreSelector = (state: RoutinesStore) => ({
  setRoutines: state.setRoutines,
  routines: state.routines,
  setCurrentRoutine: state.setCurrentRoutine,
  currentRoutine: state.currentRoutine,
  owner: state.owner,
});

export const useRoutines = () => {
  const {
    setRoutines,
    routines,
    setCurrentRoutine,
    currentRoutine,
    owner,
  } = useRoutinesStore(useShallow(routineStoreSelector));
  const { setId: setFlowChartId, setNodes, setEdges } = flowChartStore(
    useShallow(state => ({
      setId: state.setId,
      setNodes: state.setNodes,
      setEdges: state.setEdges,
    })),
  );
  const setCode = useCodeStore(state => state.setCode);
  const navigate = useNavigate();

  const routineClient = new RoutineClient({
    auth: localStorage.getItem('token') ?? '',
  });

  const routinesList = useQuery<RoutinesList[]>(
    {
      queryKey: ['listRoutines'],
      queryFn: () =>
        (routineClient.listRoutines() as unknown) as Promise<RoutinesList[]>,
      retryOnMount: false,
      refetchOnMount: false,
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
    queryClient,
  );

  useEffect(() => {
    if (routinesList.isSuccess && routines !== routinesList.data) {
      console.debug('updating routine list');
      setRoutines(routinesList.data);
    }
  }, [routinesList.isSuccess, routinesList.data]);

  const createRoutine = useMutation(
    {
      mutationFn: (payload: RoutinePayload) =>
        routineClient.createRoutine(payload),
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: ['listRoutines'],
        });
      },
      onError: error => {
        console.debug('create routine error', error);
        toast.error(
          'Error Creating your routine!',
          defaultToastErrorToastOptions,
        );
      },
    },
    queryClient,
  );

  const updateRoutine = useMutation(
    {
      mutationFn: (payload: Partial<RoutinePayload>) =>
        routineClient.updateRoutine(payload),
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: ['listRoutines'],
        });
      },
    },
    queryClient,
  );

  const deleteRoutine = useMutation(
    {
      mutationFn: (id: string) => routineClient.deleteRoutine(id),
      onSuccess: data => {
        void queryClient.invalidateQueries({
          queryKey: ['listRoutines'],
        });
        setRoutines(routines.filter(r => r.id !== data.id));
      },
    },
    queryClient,
  );

  const selectRoutine = useMutation(
    {
      mutationFn: (id: string) => routineClient.getRoutine(id),
      onSuccess: data => {
        setCurrentRoutine({
          id: data.id,
          name: data.name,
          flowChart: data.flowChart,
          code: null,
          review: null,
          updatedAt: (data.updatedAt as unknown) as number,
        });
        setNodes(data.flowChart.nodes);
        setEdges(data.flowChart.edges);
        setFlowChartId(data.id);

        if (data.code) {
          setCode({
            content: data.code.content,
            loading: false,
            updatedAt: data.code.receivedAt,
          });
        }

        navigate({
          to: '/app/$routine/flowchart',
          params: { routine: data.id },
        });
      },
    },
    queryClient,
  );

  const changeRoutineName = (id: string, name: string) => {
    setRoutines(routines.map(r => (r.id === id ? { ...r, name: name } : r)));

    updateRoutine.mutate({
      id,
      name,
    });
  };

  const createNewRoutine = (
    name: string,
    flowChart?: { nodes: FlowChartNode[]; edges: FlowChartEdge[] },
    destiny: string = 'flowchart',
  ) => {
    const id = uuidv4();
    const newRoutine = {
      id,
      name: name,
      updatedAt: Date.now(),
      flowChart: flowChart ?? {
        nodes: initialNodes,
        edges: initialEdges,
      },
      code: null,
      review: null,
    };

    setNodes(newRoutine.flowChart.nodes);
    setEdges(newRoutine.flowChart.edges);

    setCurrentRoutine(newRoutine);

    createRoutine.mutateAsync(newRoutine).then(() => {
      navigate({
        to: `/app/$routine/${destiny}`,
        params: { routine: id },
      });
    });
  };

  const toggleRoutineStatus = () => {};

  return {
    routines,
    setRoutines,
    owner,
    toggleRoutineStatus,
    createRoutine,
    currentRoutine,
    setCurrentRoutine,
    selectRoutine,
    updateRoutine,
    changeRoutineName,
    createNewRoutine,
    deleteRoutine,
  };
};
