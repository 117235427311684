import { cn } from '@packages/ui/shadcn/lib/utils.ts';
import { addSelectedKeyFn, LineBlock } from '@app/types';

interface ModelOptionsProps {
  open: boolean;
  options: { key: string; options: string[] }[];
  handleAddSelectedKey: addSelectedKeyFn;
  block: LineBlock;
  lineIndex: number;
}

export function ModelOptionsList({
  open,
  options,
  handleAddSelectedKey,
  block,
  lineIndex,
}: ModelOptionsProps) {
  return (
    <div
      className={cn(
        'absolute left-0 top-6 p-2 min-w-fit h-fit max-h-36 rounded-md bg-white shadow-sm z-30 overflow-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]',
        'transition-all duration-200 ease-in-out',
        'flex flex-col gap-2',
        open
          ? 'opacity-100 translate-y-0'
          : 'hidden opacity-0 translate-y-1 pointer-events-none',
      )}
    >
      {options.map(option => {
        if (option.key !== block.value) return null;

        return option.options.map((modelOption, index) => (
          <button
            key={`${option.key}-${index}`}
            className="w-fit p-2 text-xs text-dark-300 bg-gray-100 rounded-md transition-colors duration-150 text-left whitespace-normal hover:bg-gray-200"
            onClick={() =>
              handleAddSelectedKey({
                currentWord: block.value,
                newWord: modelOption, // Use the option value instead of the key
                position: lineIndex,
              })
            }
          >
            {modelOption}
          </button>
        ));
      })}
    </div>
  );
}
