import { FlowChartEdge } from '@app/types';
import { MarkerType } from '@xyflow/react';

export const initialNodes = [
  {
    type: 'circle',
    data: {
      label: 'Name the process',
    },
    id: '1',
    style: {
      color: '#000',
    },
    position: {
      x: -53.101931541834645,
      y: -219.99144590792855,
    },
    measured: {
      width: 106,
      height: 100,
    },
    selected: false,
    dragging: false,
  },
  {
    type: 'diamond',
    data: {
      label: 'Name the process',
    },
    id: '2',
    style: {
      color: '#000',
    },
    position: {
      x: -61.09155626963869,
      y: -59.120860906101974,
    },
    measured: {
      width: 122,
      height: 122,
    },
    selected: false,
    dragging: false,
  },
  {
    type: 'square',
    data: {
      label: 'Name the process',
    },
    id: '3',
    style: {
      color: '#000',
    },
    position: {
      x: -158.96942599196308,
      y: 139.91937081110802,
    },
    measured: {
      width: 122,
      height: 122,
    },
    selected: false,
    dragging: false,
  },
  {
    type: 'square',
    data: {
      label: 'Name the process',
    },
    id: '4',
    style: {
      color: '#000',
    },
    position: {
      x: 41.38460263427139,
      y: 140.57626926562028,
    },
    measured: {
      width: 122,
      height: 122,
    },
    selected: false,
    dragging: false,
  },
  {
    type: 'circle',
    data: {
      label: 'Name the process',
    },
    id: '5',
    style: {
      color: '#000',
    },
    position: {
      x: -52.55187636097953,
      y: 339.61650098283025,
    },
    measured: {
      width: 106,
      height: 100,
    },
    selected: false,
    dragging: false,
  },
];

export const initialEdges: FlowChartEdge[] = [
  {
    source: '1',
    sourceHandle: 'bottom',
    target: '2',
    targetHandle: 'top-target',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      height: 15,
      width: 15,
      color: '#000',
    },
    type: 'input',
    style: {
      stroke: '#000',
      strokeWidth: 1,
    },
    id: 'xy-edge__1bottom-2top-target',
  },
  {
    source: '2',
    sourceHandle: 'bottom',
    target: '3',
    targetHandle: 'top-target',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      height: 15,
      width: 15,
      color: '#000',
    },
    type: 'input',
    style: {
      stroke: '#000',
      strokeWidth: 1,
    },
    id: 'xy-edge__2bottom-3top-target',
  },
  {
    source: '2',
    sourceHandle: 'bottom',
    target: '4',
    targetHandle: 'top-target',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      height: 15,
      width: 15,
      color: '#000',
    },
    type: 'input',
    style: {
      stroke: '#000',
      strokeWidth: 1,
    },
    id: 'xy-edge__2bottom-4top-target',
  },
  {
    source: '3',
    sourceHandle: 'bottom',
    target: '5',
    targetHandle: 'top-target',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      height: 15,
      width: 15,
      color: '#000',
    },
    type: 'input',
    style: {
      stroke: '#000',
      strokeWidth: 1,
    },
    id: 'xy-edge__3bottom-5top-target',
  },
  {
    source: '4',
    sourceHandle: 'bottom',
    target: '5',
    targetHandle: 'top-target',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      height: 15,
      width: 15,
      color: '#000',
    },
    type: 'input',
    style: {
      stroke: '#000',
      strokeWidth: 1,
    },
    id: 'xy-edge__4bottom-5top-target',
  },
];
