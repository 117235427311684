import { memo, useState } from 'react';
import { Check } from 'lucide-react';
import { Button } from '@packages/ui/shadcn/ui/button.tsx';

export const SaveChangesButton = memo(
  ({ onClick }: { onClick: () => void }) => {
    const [isSaving, setIsSaving] = useState(false);

    const handleClick = () => {
      setIsSaving(true);

      onClick();

      // Reset after 1 second
      setTimeout(() => {
        setIsSaving(false);
      }, 1000);
    };

    return (
      <Button
        variant="outline"
        className="p-3 w-full self-end flex items-center justify-center rounded-lg bg-dark-300 text-white transition-colors text-xs max-w-[170px]"
        onClick={handleClick}
      >
        {isSaving ? (
          <div className="flex items-center gap-2 fade-in">
            <Check size={16} />
            <span>Saved</span>
          </div>
        ) : (
          <span className="fade-in"> Save Changes </span>
        )}
      </Button>
    );
  },
);
