import {
  Edge,
  Node,
  NodeProps,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
} from '@xyflow/react';
import { ReactNode } from 'react';

export type NodeData = {
  label: string;
  objectives?: string;
  analysis?: ProcessedLine[];
  modelOptions?: ModelOptions;
};

export type FlowChartNode = Node & { data: NodeData };
export type FlowChartEdge = Edge & { data?: { label?: string } };

export interface InputList {
  text: string;
  var_options?: string[];
  model_options?: string[];
}

export type FlowChartState = {
  nodes: FlowChartNode[];
  edges: FlowChartEdge[];
  onNodesChange: OnNodesChange<FlowChartNode>;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  setNodes: (nodes: FlowChartNode[]) => void;
  setEdges: (edges: Edge[]) => void;
  updateNode: (node: Partial<FlowChartNode>) => void;
  updateEdge: (node: Partial<FlowChartEdge>) => void;
  id: string;
  setId: (id: string) => void;
  resetStore: () => void;
};

export interface ShapesProps extends NodeProps<FlowChartNode> {
  children: ReactNode;
  resized: boolean;
  onResizedChange: () => void;
  style: React.CSSProperties;
  onClick: () => void;
}

export enum FlowChartStep {
  FLOW_CHART = 'flowchart',
  CODE = 'code',
  TEST = 'test',
}

export interface SetShapeDataProps {
  label?: string;
  objectives?: string;
  analysis?: ProcessedLine[];
  modelOptions?: ModelOptions;
}

export type SetShapeDataFn = ({
  label,
  objectives,
  analysis,
  modelOptions,
}: SetShapeDataProps) => void;

// New Types
export type HighlightType = 'var' | 'model';

export type LineBlock = {
  type: 'normal' | HighlightType;
  value: string;
};

export type ProcessedLine = LineBlock[];

export type KeyWordOptions = {
  key: string;
  list: string[];
  type: HighlightType;
};
export type KeyWordsList = KeyWordOptions[];

export interface ShapeColor {
  shape: string;
  lightBg: string;
  darkBg: string;
  text: string;
}

export type addSelectedKeyFn = (_: {
  currentWord: string;
  newWord: string;
  position: number;
}) => void;

export type ModelOptions = { key: string; options: string[] }[];
