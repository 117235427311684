import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@rangpt/components/ui/resizable.tsx';
import { Welcome } from '@rangpt/components/welcome.tsx';
import { Children } from '@rangpt/types/jsx.types.ts';
import { useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@rangpt/components/ui/accordion.tsx';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@rangpt/components/ui/alert.tsx';
import { ActionButton } from '@rangpt/components/buttons';
import { useCommandInterface } from '@rangpt/hooks';
import { SendArrow } from '@rangpt/components/ui/sendArrow.tsx';
import { INSIGHTS, InsightsObj } from '@rangpt/types';
import { WS_URL } from '@rangpt/types/config.ts';
import useSession from '@rangpt/hooks/useSession.ts';
import { useNavigate } from '@tanstack/react-router';

const Content = ({ children }: Readonly<Children>) => {
  if (children) {
    return (
      <ResizablePanel
        defaultSize={60}
        className="w-full h-full flex min-h-0 min-w-xl items-center justify-center p-4"
      >
        {children}
      </ResizablePanel>
    );
  }
  return (
    <ResizablePanel
      className="fade-in h-full flex items-center justify-center min-w-3xl"
      defaultSize={60}
    >
      <Welcome />
    </ResizablePanel>
  );
};

const InsightsList = ({
  insightSelected,
  onClick,
  insights,
}: {
  insightSelected: INSIGHTS;
  onClick: (insight: InsightsObj) => void;
  insights: Record<string, InsightsObj[]> | null;
}) => {
  if (!insights) return;

  const data = insights[insightSelected];

  return data.map(item => {
    const handleSelectInsight = () => {
      onClick(item);
    };

    return (
      <button
        key={item.question}
        className="w-full p-2"
        onClick={handleSelectInsight}
      >
        <Alert>
          {/*<Terminal width={14} height={14} />*/}
          <AlertTitle className="text-start">{item.question}</AlertTitle>
          <AlertDescription className="text-gray-400 text-xs text-start">
            Updated in the last 7 hours
          </AlertDescription>
        </Alert>
      </button>
    );
  });
};

export const Insights = () => {
  const navigate = useNavigate();
  const { insights } = useSession();
  const { loading, prompt, chat, currentPrompt } = useCommandInterface();
  const [currentInsight, setCurrentInsight] = useState<InsightsObj | null>(
    null,
  );

  const handleSelect = (insight: InsightsObj) => {
    setCurrentInsight(insight);
  };

  const handleRunInsight = () => {
    if (!currentInsight) return;
    prompt({
      message: currentInsight?.question,
      origin: 'insights',
      blob: undefined,
      blobType: undefined,
    });

    navigate({
      to: '/commandInterface',
    });
  };

  const needFeedback = () => {
    if (loading) return false;
    if (!chat[currentPrompt]?.code) return false;
    if (chat[currentPrompt].code!.length > 0) {
      return !chat[currentPrompt].feedback;
    }
  };

  const runInsightButtonText = () => {
    if (needFeedback()) {
      return 'Need Feedback at Command Interface.';
    }

    if (loading) {
      return "There's a Prompt running!";
    }

    return (
      <>
        Load Insight
        <SendArrow width={15} height={15} fill="#FFF" />
      </>
    );
  };

  const formatImgUrl = (url: string) => {
    return `${WS_URL}${url.slice(1)}`;
  };

  return (
    <ResizablePanelGroup
      direction="horizontal"
      className="col-span-2 row-span-1 flex flex-row"
    >
      <ResizablePanel
        className="flex flex-col border-r border-[#CBD5E1] max-h-full overflow-hidden min-w-[400px]"
        defaultSize={40}
      >
        <div className="w-full h-full flex flex-col gap-3 items-center justify-center p-4">
          <h2 className="text-lg text-start self-start py-2">
            Select one Insight and see the pre-loaded result.
          </h2>
          <Accordion type="single" collapsible className="w-full text-md">
            {Object.keys(insights ?? {}).map(insight => (
              <AccordionItem key={insight} value={insight} className="text-md">
                <AccordionTrigger className="hover:no-underline text-md">
                  {insight}
                </AccordionTrigger>
                <AccordionContent className="max-h-96 overflow-y-scroll">
                  <InsightsList
                    insights={insights}
                    key={insight}
                    insightSelected={insight as INSIGHTS}
                    onClick={handleSelect}
                  />
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </ResizablePanel>
      <ResizableHandle />
      <ResizablePanel defaultSize={60} className="min-w-xl">
        <Content>
          {currentInsight ? (
            <div className="w-full h-full flex flex-col p-2 items-center">
              <section className="flex flex-col gap-3 self-start">
                <h2>{currentInsight.question}</h2>
                <p className="text-gray-400 text-sm">
                  {currentInsight.summarization}
                </p>
              </section>
              <img
                src={formatImgUrl(currentInsight.image_url)}
                alt="Insight Presumed result"
                className="m-auto"
              />
              <ActionButton
                disabled={needFeedback()}
                loading={loading}
                onClick={handleRunInsight}
              >
                {runInsightButtonText()}
              </ActionButton>
            </div>
          ) : null}
        </Content>
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};
