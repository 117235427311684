import { SheetContent, SheetTitle } from '@packages/ui/shadcn/ui/sheet.tsx';
import { ChangesMade } from '@components/ObjectivesArea/ChangesMade.tsx';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { ObjectivesArea } from '@components/ObjectivesArea';
import { ModelOptions, ProcessedLine, SetShapeDataFn } from '@app/types';
import { X } from 'lucide-react';
import { useDebouncedCallback } from 'use-debounce';

interface ContentProps {
  label: string;
  objectives?: string;
  analysis?: ProcessedLine[];
  _modelOptions?: ModelOptions;
  type: string;
  saveChanges: SetShapeDataFn;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const shapeIndexing: Record<string, string> = {
  circle: 'Start/End',
  square: 'Process',
  diamond: 'Decision',
  rectangle: 'Loop',
};

export type HandleDebouncedChange = (e: ChangeEvent<HTMLInputElement>) => void;

export const Content = ({
  label,
  objectives,
  analysis,
  type,
  saveChanges,
  setOpen,
  _modelOptions,
}: ContentProps) => {
  const handleDebouncedChange = useDebouncedCallback<HandleDebouncedChange>(
    e => {
      saveChanges({ label: e.target.value });
    },
    1000,
  );

  return (
    <SheetContent
      className="absolute right-0 top-24 min-w-[400px] !max-w-full w-[30%] h-[calc(100%-6.04rem)] bg-neutral-100 flex flex-col gap-9 items-start justify-start py-6 px-4 overflow-scroll"
      aria-describedby={undefined}
      onInteractOutside={() => setOpen(false)}
    >
      <button
        onClick={() => setOpen(false)}
        className="absolute right-4 top-6 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary"
      >
        <X className="h-5 w-5" />
        <span className="sr-only">Close</span>
      </button>
      <div className="flex flex-col items-start justify-start gap-4">
        <SheetTitle className="text-lg text-dark-300 flex items-center gap-1">
          Set Objective -{' '}
          <span className="text-xs text-gray-500 pt-0.5">
            {shapeIndexing[type]}
          </span>
        </SheetTitle>
        <span className="text-wrap text-sm text-gray-600">
          This objectives will be used on the code generation. Set your
          objectives, select the key phrases and see values options.
        </span>
      </div>

      <section className="relative w-full">
        <input
          type="text"
          id="label"
          name="label"
          defaultValue={label}
          onChange={handleDebouncedChange}
          className="rounded-lg bg-neutral-200 px-4 py-5 pb-1 w-full text-dark-300 focus:outline-none"
        />
        <label
          htmlFor="label"
          className="text-xs text-gray-500 absolute top-1 left-4"
        >
          Process name
        </label>
      </section>

      <ObjectivesArea.Container
        objectives={objectives}
        saveChanges={saveChanges}
        analysis={analysis}
        _modelOptions={_modelOptions}
      />

      <ChangesMade />
    </SheetContent>
  );
};
