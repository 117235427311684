import {
  Background,
  BackgroundVariant,
  Controls,
  NodeProps,
  Panel,
  ReactFlow,
} from '@xyflow/react';
import { useFlowChart, useRoutines, useUiStore } from '@app/hooks';
import { SquareShape } from '@components/flowchart/shapes/square.tsx';
import { CircleShape, RectangleShape } from '@components/flowchart/shapes';
import { DiamondShape } from '@components/flowchart/shapes/Diamond.tsx';
import { InputEdge } from '@components/flowchart/shapes/edge.tsx';
import { ShapesHOC } from '@components/flowchart/shapes/ShapesHOC.tsx';
import { FlowChartNode } from '@app/types';
import { useNavigate } from '@tanstack/react-router';
import { MenuPanel } from '@components/flowchart';
import { CreateRoutineModal } from '@components/home/CreateRoutine.tsx';
import { Button } from '@packages/ui/shadcn/ui/button.tsx';
import { Terminal } from 'react-feather';
import { useCodeStore } from '@app/stores/code.store.ts';
import { useSocket } from '@packages/hooks';
import { SOCKET_EVENTS } from '@packages/api'; // Executing HOC

// Executing HOC
const nodeTypes = {
  square: (props: NodeProps<FlowChartNode>) => ShapesHOC(SquareShape, props),
  circle: (props: NodeProps<FlowChartNode>) => ShapesHOC(CircleShape, props),
  diamond: (props: NodeProps<FlowChartNode>) => ShapesHOC(DiamondShape, props),
  rectangle: (props: NodeProps<FlowChartNode>) =>
    ShapesHOC(RectangleShape, props),
};

const edgeTypes = {
  input: InputEdge,
};

export const FlowChartContainer = () => {
  const {
    nodes,
    edges,
    currentRoutine,
    onNodesChange,
    onEdgesChange,
    onConnect,
    handleAddNode,
    id,
  } = useFlowChart();
  const navigate = useNavigate();
  const { isCodeEnabled } = useUiStore();
  const { createNewRoutine, createRoutine } = useRoutines();
  const setCode = useCodeStore(state => state.setCode);
  const { emitter } = useSocket();

  const handleGoToCode = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!currentRoutine.name) {
      return;
    }
    e.stopPropagation();

    emitter('flow_chart', {
      flow_chart_id: id,
      flow_chart_payload: {
        nodes,
        edges,
      },
      origin: 'app_builder',
    });
    setCode({
      content: null,
      loading: true,
      updatedAt: null,
    });
    navigate({
      to: '/app/$routine/code/development',
      params: { routine: currentRoutine.id },
    });
  };

  const handleCreateRoutineBasedOnFlowChart = (name: string) => {
    createNewRoutine(
      name,
      {
        nodes,
        edges,
      },
      'code/development',
    );

    setCode({
      content: null,
      loading: true,
      updatedAt: null,
    });

    setTimeout(() => {
      emitter(SOCKET_EVENTS.FLOW_CHART, {
        flow_chart_id: id,
        flow_chart_payload: {
          nodes,
          edges,
        },
        origin: 'app_builder',
      });
      navigate({
        to: '/app/$routine/code/development',
        params: { routine: currentRoutine.id },
      });
    }, 500);
  };

  return (
    <div className="w-full h-full">
      <ReactFlow
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
        proOptions={{
          hideAttribution: true,
        }}
      >
        <MenuPanel handleAddNode={handleAddNode} />

        <Controls className="!bottom-28" />
        <Background
          variant={BackgroundVariant.Dots}
          color="#C4C4C4"
          style={{ backgroundColor: '#E5E5E5' }}
        />

        <Panel position="bottom-right" className="w-fit !bottom-28">
          <CreateRoutineModal
            onConfirm={handleCreateRoutineBasedOnFlowChart}
            description="To run the code, you need to create a routine with this flowchart."
            loading={createRoutine.isPending}
          >
            <Button
              variant="outline"
              onClick={handleGoToCode}
              className="bg-white rounded-md outline-none shadow-md py-2 flex gap-2 disabled:hidden"
              disabled={!isCodeEnabled}
            >
              <Terminal width={16} height={16} />
              Code
            </Button>
          </CreateRoutineModal>
        </Panel>
      </ReactFlow>
    </div>
  );
};
