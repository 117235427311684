import React, { memo, RefObject, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface ObjectivesTextAreaProps {
  spanRef: RefObject<HTMLSpanElement> | null;
  text: string;
  setText: (text: string) => void;
}

export const ObjectivesTextArea = memo(
  ({ spanRef, text, setText }: ObjectivesTextAreaProps) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const cursorPositionRef = useRef<number | null>(null);
    const previousValueRef = useRef<string>('');

    useEffect(() => {
      if (textareaRef.current && cursorPositionRef.current !== null) {
        textareaRef.current.setSelectionRange(
          cursorPositionRef.current,
          cursorPositionRef.current,
        );
        cursorPositionRef.current = null;
      }

      const handleScroll = () => {
        if (textareaRef.current && spanRef?.current) {
          const isScrolled = textareaRef.current.scrollTop > 15;

          if (isScrolled) {
            spanRef.current.classList.remove('top-3', 'left-4');
            spanRef.current.classList.add('bottom-full', 'left-4');
          } else {
            spanRef.current.classList.add('top-3', 'left-4');
            spanRef.current.classList.remove('top-0', 'left-2');
          }
        }
      };

      const textarea = textareaRef.current;
      if (textarea) {
        textarea.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (textarea) {
          textarea.removeEventListener('scroll', handleScroll);
        }
      };
    }, [text]);

    const deletingDebounceAdjust = useDebouncedCallback(
      (
        lines: string[],
        e: React.ChangeEvent<HTMLTextAreaElement>,
        deleting: boolean,
      ) => {
        if (!deleting) return;

        const value = e.target.value;
        const enumeratedLines = lines.map((line, index) => {
          const lineNumber = index + 1;

          return `${lineNumber}. ${line.replace(/^\d+\.\s*/, '')}`;
        });

        const text = enumeratedLines.join('\n');

        setText(text);
        previousValueRef.current = value; // Update previous value
      },
      1000,
    );

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      const previousValue = previousValueRef.current;
      const isDeleting = value.length < previousValue.length; // Detect if user is deleting

      const lines = value.split('\n');

      // If the text area is completely empty, don't apply numbering
      if (value.trim() === '') {
        setText(value);
        previousValueRef.current = value; // Update previous value
        return;
      }

      // Handle the case where there's only one line
      if (lines.length === 1) {
        const str = lines[0].includes('1.') ? value : `1. ${value}`;
        setText(str);
        previousValueRef.current = str;
        return;
      }

      const enumeratedLines = lines.map((line, index) => {
        const lineNumber = index + 1;

        // If user is deleting, leave the line unchanged (allowing deletion of the number)
        if (isDeleting || line === `${lineNumber}.`) {
          return line;
        }

        // enforce numbering when typing
        return `${lineNumber}. ${line.replace(/^\d+\.\s*/, '')}`;
      });

      setText(enumeratedLines.join('\n'));
      previousValueRef.current = value; // Update previous value

      // Track the cursor position for future handling
      if (cursorPositionRef.current === null) {
        cursorPositionRef.current = e.target.selectionStart;
      }

      deletingDebounceAdjust(lines, e, isDeleting);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const textarea = e.currentTarget;
        const cursorPosition = textarea.selectionStart;
        const textBeforeCursor = text.substring(0, cursorPosition);
        const textAfterCursor = text.substring(cursorPosition);

        const lines = textBeforeCursor.split('\n');
        const currentLineNumber = lines.length;
        const newLineNumber = currentLineNumber + 1;

        const newText = `${textBeforeCursor}\n${newLineNumber}. ${textAfterCursor}`;
        setText(newText);

        cursorPositionRef.current =
          textBeforeCursor.length + newLineNumber.toString().length + 3;
      }
    };

    return (
      <section className="w-full relative min-h-72 h-full max-h-2xl">
        <span
          className="absolute text-xs text-gray-500 top-3 left-4 z-20"
          ref={spanRef}
        >
          Objectives
        </span>
        <textarea
          onKeyDown={handleKeyDown}
          value={text}
          ref={textareaRef}
          onChange={handleChange}
          name="objectives"
          id="objectives"
          className="w-full h-full bg-neutral-200 rounded-md p-4 pt-10 text-sm outline-none focus:outline-none overflow-scroll z-10 resize-none"
        />
      </section>
    );
  },
);
