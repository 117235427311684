import { NodeProps } from '@xyflow/react';
import { FlowChartNode, SetShapeDataProps, ShapesProps } from '@app/types';
import { useEffect, useRef, useState } from 'react';
import { ShapeDrawer } from '@components/ShapeDrawer';
import { useFlowChart } from '@app/hooks';
import { CheckCircle2Icon } from 'lucide-react';
import { ShapesHelper } from '@app/utils/ShapesHelper.ts';
import { Sheet, SheetTrigger } from '@packages/ui/shadcn/ui/sheet.tsx';

export const ShapesHOC = (
  Element: (props: ShapesProps) => JSX.Element,
  props: NodeProps<FlowChartNode>,
) => {
  const { id, data, selected, type } = props;
  const { updateNode } = useFlowChart();
  const contentEditableRef = useRef<HTMLDivElement>(null);
  const [resized, setResized] = useState(false);
  const [open, setOpen] = useState(false);

  const shapesHelper = new ShapesHelper();

  useEffect(() => {
    if (contentEditableRef.current) {
      contentEditableRef.current.textContent = data.label;
    }
  }, [data]);

  const onResizedChange = () => {
    if (resized) return;
    setResized(true);
  };

  function saveChanges({
    objectives,
    analysis,
    label,
    modelOptions,
  }: SetShapeDataProps) {
    if (objectives || analysis) {
      setTimeout(() => setOpen(false), 1000);
    }

    updateNode({
      id,
      data: {
        ...data,
        objectives: objectives ?? data.objectives,
        label: label ?? data.label,
        analysis: analysis ?? data.analysis,
        modelOptions: modelOptions ?? data.modelOptions,
      },
    });
  }

  const handleContentChange = (evt: React.FormEvent<HTMLDivElement>) => {
    const value = evt.currentTarget.textContent ?? '';
    if (value.length <= 20) {
      saveChanges({ label: value });
    } else {
      // Prevent input if length exceeds 20
      evt.currentTarget.textContent = value.slice(0, 20);
    }
  };

  function switchDrawerOpen() {
    setOpen(prev => !prev);
  }

  const hasObjectives = data.objectives ? data.objectives.length > 1 : false;
  const colors = shapesHelper.getShapeColors(type);

  if (!colors) return;

  return (
    <Sheet modal={false} open={open}>
      <SheetTrigger
        asChild
        className="absolute top-1 right-1 z-20 hidden aria-selected:flex"
        aria-selected={selected}
      >
        <Element
          {...props}
          resized={resized}
          onResizedChange={onResizedChange}
          style={{
            background: hasObjectives ? colors.darkBg : colors.lightBg,
          }}
          onClick={switchDrawerOpen}
        >
          {hasObjectives && (
            <CheckCircle2Icon
              className="w-4 h-4 absolute top-1 left-1"
              style={{
                color: colors.text,
              }}
              fill="#fff"
            />
          )}
          <section className="w-full max-w-full m-auto px-2 z-10 relative">
            <section className="w-full max-w-full flex items-center gap-1 justify-center">
              <div
                ref={contentEditableRef}
                contentEditable
                onInput={handleContentChange}
                className="w-full max-w-full outline-none text-xs text-center overflow-hidden whitespace-nowrap text-ellipsis"
                style={{
                  color: colors.text,
                }}
              />
            </section>
          </section>
        </Element>
      </SheetTrigger>
      <ShapeDrawer.Content
        label={data.label}
        type={type}
        saveChanges={saveChanges}
        objectives={data.objectives}
        analysis={data.analysis}
        setOpen={setOpen}
        _modelOptions={data.modelOptions}
      />
    </Sheet>
  );
};
