import { useAccManagement } from '@management/hooks';
import { AccManagement } from '@management/components/AccountManagement';
import { Button } from '@packages/ui/shadcn/ui/button.tsx';
import { FadeIconLoading } from '@packages/ui';

export function AccountManagement() {
  const {
    users,
    isLoadingUserList,
    switchUserState,
    usersActives,
  } = useAccManagement();

  const addUserTrigger = (
    <Button
      variant="outline"
      className="bg-dark-1 text-light-3 rounded-md shadow-md"
    >
      Add user
    </Button>
  );

  if (isLoadingUserList) {
    return <FadeIconLoading text="Loading team users" />;
  }

  return (
    <div className="w-full h-full flex flex-col items-center justify-start gap-6">
      <header className="w-full flex items-center justify-end">
        <AccManagement.Modal type="CREATE" trigger={addUserTrigger} />
      </header>
      <div className="w-full py-3">
        <AccManagement.UserListTable
          users={users}
          switchUserState={switchUserState}
          usersActive={usersActives}
        />
      </div>
    </div>
  );
}
