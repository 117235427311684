import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@packages/ui/shadcn/ui/select.tsx';
import { FormEvent } from 'react';
import { transformForInput } from '@management/utils/formatText.ts';
import { DatabaseInfoInput } from '../database/databaseInfoInput.tsx';
import { useDatabaseManagement } from '@management/hooks';
import { Upload } from '@management/components/FileUpload/Upload.tsx';
import { DatabaseDefaultButton } from '../database/DatabaseDefaultButton.tsx';

type DatabaseSettingsFormProps = Pick<
  ReturnType<typeof useDatabaseManagement>,
  | 'updateClient'
  | 'handleConnectTables'
  | 'loading'
  | 'setCurrentDatabase'
  | 'sendSelectedColumns'
  | 'tables'
  | 'defaultForm'
  | 'currentDatabase'
  | 'showConnect'
  | 'databaseInfo'
  | 'updateDatabaseInfo'
  | 'handleLoadCsv'
>;

export const DatabaseSettingsForm = ({
  updateClient,
  handleConnectTables,
  loading,
  setCurrentDatabase,
  sendSelectedColumns,
  tables,
  defaultForm,
  currentDatabase,
  showConnect,
  databaseInfo,
  updateDatabaseInfo,
  handleLoadCsv,
}: DatabaseSettingsFormProps) => {
  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const data = Object.fromEntries(formData) as Record<
      string,
      string | boolean
    >;

    for (const item in data) {
      if (data[item] === 'on') {
        data[item] = true;
      }
    }

    updateDatabaseInfo(data);
  };

  const handleDatabaseChange = (value: string) => {
    setCurrentDatabase(value);
  };

  return (
    <div className="w-full max-w-full flex-none">
      <form
        onSubmit={submit}
        className="flex flex-col items-start justify-center w-full gap-4 py-4 relative flex-wrap xl:flex-nowrap"
      >
        <div className="flex flex-col w-full bg-light-4 rounded-md shadow-lg p-6 py-4 pb-6">
          <section className="flex gap-10 items-center p-2 pb-6">
            <p className="text-sm font-dark-1 font-medium">Details</p>
            <Select onValueChange={handleDatabaseChange}>
              <SelectTrigger className="w-fit bg-white border rounded-md shadow-sm gap-3 p-3 px-4">
                <SelectValue
                  placeholder={transformForInput(currentDatabase)}
                  className="font-medium"
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {Object.keys(defaultForm).map(key => (
                    <SelectItem value={key} key={key}>
                      {transformForInput(key)}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </section>
          <div className="flex flex-col items-start justify-center w-full gap-3">
            {defaultForm[currentDatabase].map(item => (
              <DatabaseInfoInput
                key={item.field}
                defaultValue={
                  databaseInfo.find(
                    database => database.database_form_name === currentDatabase,
                  )?.[item.field]
                }
                field={item.field}
                disabled={false}
                tooltip={item.tooltip}
              />
            ))}
          </div>
        </div>
        <section className="w-full flex gap-6 items-center justify-start">
          <DatabaseDefaultButton
            loadingText="Saving"
            isLoading={updateClient.isPending}
            disabled={updateClient.isPending || loading}
            type="submit"
          >
            Save
          </DatabaseDefaultButton>
          <DatabaseDefaultButton
            type="button"
            hidden={!showConnect}
            onClick={handleConnectTables}
            isLoading={loading}
            loadingText="Connecting"
            disabled={loading}
          >
            Connect
          </DatabaseDefaultButton>

          {tables.length > 0 && (
            <section className="flex gap-3 items-center justify-start ml-auto">
              <Upload handleLoadCsv={handleLoadCsv} />
              <DatabaseDefaultButton
                type="button"
                onClick={sendSelectedColumns}
                isLoading={updateClient.isPending}
                disabled={updateClient.isPending}
                loadingText="Saving"
              >
                Save selected Columns
              </DatabaseDefaultButton>
            </section>
          )}
        </section>
      </form>
    </div>
  );
};
