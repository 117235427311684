import { useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { WS_PATH, WS_URL } from '@rangpt/types/config.ts';
import { v4 as uuidv4 } from 'uuid';
import useSocketStore from '@rangpt/stores/useSocketStore.ts';
import { SocketEvents } from '@rangpt/types';
import useCommandInterfaceStore from '@rangpt/stores/useCommandInterfaceStore.ts';
import { removeFirstAndLastLine } from '@rangpt/lib/utils.ts';
import { useCommandInterface } from '@rangpt/hooks/useCommandInterface.ts';
import useSession from '@rangpt/hooks/useSession.ts';
import { updateSession } from '@rangpt/apis/appHistory.api.ts';
import { useAuth } from '@packages/hooks';
import { WSVarsEvent } from '@packages/api';

// @TODO: refactor this hook
const useSocket = () => {
  const connect = useRef<Socket | null>(null);
  const {
    socket,
    setSocket,
    config,
    setConfig,
    setForceReconnect,
    forceReconnect,
  } = useSocketStore();

  const { currentUser } = useAuth();

  const commandInterfaceStore = useCommandInterfaceStore();

  const { sendCommand, lastCommandPromptData } = useCommandInterface();
  const {
    setInsights,
    updateSessionName,
    setTools,
    createSessionIfNeeded,
    session,
  } = useSession();

  useEffect(() => {
    if (!config.username) {
      if (!currentUser) {
        console.debug('~ currentUser is null, restarting socket connection');
        return;
      }

      setConfig({
        ...config,
        username: currentUser.name,
      });
      return;
    }

    if (socket && !forceReconnect) return;

    // Connect to the Socket.io server
    const socketIo: Socket = io(WS_URL, {
      forceNew: forceReconnect,
      reconnectionDelayMax: 2000,
      reconnectionAttempts: 2,
      path: WS_PATH,
      query: config,
    });

    socketIo.on('vars_opts', (e: WSVarsEvent) => {
      console.debug(e);
      if (e.options) {
        console.debug('updating local vars store');
        localStorage.setItem('options', JSON.stringify(e.options));
      }
    });

    socketIo.on('connect', function () {
      console.log('Connected to the server!');
      // after new connection, reset forceReconnect
      setForceReconnect(false);
    });

    socketIo.on('disconnect', function () {
      console.log('Disconnected to the server!');
    });

    socketIo.on('answer', (event: SocketEvents) => {
      commandInterfaceStore.addResponse(event, 'answer', event.final);
    });

    socketIo.on('code', (_event: SocketEvents) => {
      const event: SocketEvents = {
        ..._event,
        text: removeFirstAndLastLine(_event.text),
      };
      commandInterfaceStore.addResponse(event, 'code');
    });

    socketIo.on('chart', async (event: SocketEvents) => {
      commandInterfaceStore.addResponse(event, 'chart');

      const cache = await caches.open('graphs');
      const options = {
        ignoreSearch: true,
        ignoreMethod: true,
        ignoreVary: true,
      };
      try {
        await cache
          .add(new Request(`${WS_URL}/charts/${event.chart_id}.png`))
          .catch(e => {
            throw new Error('Error when adding image to cache: ' + e);
          });

        const response = await cache.match(
          `${WS_URL}/charts/${event.chart_id}.png`,
          options,
        );

        if (!response) {
          throw new Error('Error retrieving img from cache');
        }

        const blob = await response.blob();

        console.debug('blob on socket event', blob);

        // @TODO: Pass cache add to addResponse of chart
        // commandInterfaceStore.setChartCache(
        //   event,
        //   URL.createObjectURL(blob),
        //   commandInterfaceStore.chat[commandInterfaceStore.chat.length - 1].chart.length - 1,
        // );
      } catch (e) {
        console.debug(e);
      }
    });

    socketIo.on('html', (event: SocketEvents) => {
      commandInterfaceStore.addResponse(event, 'html');
    });

    socketIo.on('thought', (event: SocketEvents) => {
      commandInterfaceStore.addResponse(event, 'answer');
    });

    socketIo.on('tools', (event: SocketEvents) => {
      if (!event.tools) return;
      setTools(event.tools);
    });

    socketIo.on('final', (event: SocketEvents) => {
      if (event.origin === 'aira_chat') {
        commandInterfaceStore.setLoading(false);
        if (
          !commandInterfaceStore.chat[commandInterfaceStore.currentPrompt].code
        ) {
          console.debug(
            '~ final before code is generated',
            commandInterfaceStore.chat[commandInterfaceStore.currentPrompt]
              .code,
          );
          commandInterfaceStore.addResponse(
            {
              chat_id: event.chat_id,
              prompt_id: event.prompt_id,
              code_id: uuidv4(),
              text: 'Prompt generation stopped!',
              timestamp: event.timestamp,
              origin: 'aira_chat',
            },
            'code',
          );
        }
        commandInterfaceStore.addResponse(event, 'answer', true);

        const currentPrompted = lastCommandPromptData();
        const sessionName = session.currentSession.name;

        createSessionIfNeeded()
          .then(() => {
            sendCommand();

            if (!sessionName || sessionName === 'Empty Session') {
              socketIo.emit('name_session', {
                session_id: config.chat_id,
                username: config.username,
                timestamp: Date.now(),
                prompt_text: currentPrompted.prompt.content,
                origin: event.origin,
              });
            }
          })
          .catch(err => console.error(err));
      }
    });

    socketIo.on('session_name', (event: Record<string, string>) => {
      updateSessionName({
        name: event.name,
        id: event.session_id,
      });

      updateSession({
        id: config.chat_id,
        name: event.name,
      })
        .then(res => console.log(res))
        .catch(err => console.debug(err));
    });

    socketIo.on('insights', (event: SocketEvents) => {
      if (!event.insights) return;
      setInsights(JSON.parse(event.insights));
    });

    socketIo.onAny(() => {
      //@TODO: logout user if token has expired
    });

    connect.current = socketIo;

    setSocket(socketIo);
  }, [config, forceReconnect]);

  return {
    socket: socket!,
    config,
    setConfig,
    setForceReconnect,
  };
};

export { useSocket };
