interface OptionBtnProps {
  onClick: (_: string) => void;
  option: string;
}

export function OptionBtn({ onClick, option }: OptionBtnProps) {
  const click = () => onClick(option);

  return (
    <button
      key={option}
      className="w-full p-2 text-xs text-dark-300 bg-gray-100 rounded-md transition-colors duration-150 text-left whitespace-normal hover:bg-gray-200"
      onClick={click}
    >
      {option}
    </button>
  );
}
