import { Button } from '@packages/ui/shadcn/ui/button.tsx';
import { Trash2 } from 'react-feather';
import { Upload } from '@management/components/FileUpload/Upload.tsx';
import { useMemoryManagement } from '@management/hooks';
import { DefaultModal } from '@management/components/database/tables/defaultModal.tsx';
import { useState } from 'react';

export const MemoryManagementPage = () => {
  const {
    memoryList,
    deleteItem,
    handleLoadCsv,
    clearMemory,
    addItem,
  } = useMemoryManagement();
  const [inputValue, setInputValue] = useState('');

  const handleConfirm = () => {
    addItem(inputValue);
    setInputValue('');
  };

  const memoryItemsBalancer = () => {
    if (memoryList.length === 0) {
      return (
        <p className="text-center text-xs mt-4">
          You don't have any memory items.
        </p>
      );
    }

    return memoryList.map((item, index) => (
      <div className="p-3 rounded-md border flex items-start" key={item}>
        <p className="text-sm pr-4 flex-grow break-words min-w-12">{item}</p>
        <Button
          id={String(index)}
          variant="ghost"
          className="hover:bg-transparent flex-shrink-0"
          size="icon"
          onClick={deleteItem}
        >
          <Trash2 className="w-4 h-4" />
        </Button>
      </div>
    ));
  };

  return (
    <div className="w-full h-full bg-transparent flex flex-col items-center justify-start gap-6">
      <div className="w-full h-fit max-h-full overflow-y-auto py-3 px-6 bg-white rounded-lg shadow-lg">
        <div className="space-y-2">{memoryItemsBalancer()}</div>

        <div className="mt-4 w-full flex items-center justify-between">
          <DefaultModal
            loading={false}
            onConfirm={handleConfirm}
            trigger={
              <Button
                variant="outline"
                className="w-fit bg-transparent border-gray-400 text-dark-300"
              >
                Add new
              </Button>
            }
            title="Add item to memory"
          >
            <section className="w-full relative">
              <textarea
                id="memory-item"
                placeholder="Memory description"
                rows={6}
                className="w-full bg-light-2 rounded-md p-4 text-sm outline-none focus:outline-none overflow-scroll"
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
              />
            </section>
          </DefaultModal>
          <Button
            variant="outline"
            className="w-fit bg-transparent border-red-400 text-red-400 hover:bg-red-50"
            onClick={clearMemory}
          >
            Clear memory
          </Button>
        </div>
      </div>
      <section className="self-start">
        <Upload handleLoadCsv={handleLoadCsv} />
      </section>
    </div>
  );
};
