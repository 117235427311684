import { createFileRoute, Navigate } from '@tanstack/react-router';
import { ManagementLayout } from '@/pages/ManagementLayout.tsx';
import { useAuth } from '@hooks/useAuth.ts';

export const Route = createFileRoute('/management')({
  component: ManagementAdminWrapper,
});

function ManagementAdminWrapper() {
  const { currentUser } = useAuth();

  console.debug(currentUser);

  if (!currentUser?.isAdmin) {
    console.debug('user is not admin');
    return <Navigate to="/dashboard" />;
  }

  return <ManagementLayout />;
}
