import { useRef } from 'react';
import { Input } from '@rangpt/components/chat/input.tsx';
import { cn } from '@packages/ui/shadcn/lib/utils.ts';

// type SuggestedPrompt = {
//   icon: React.ReactNode;
//   label: string;
//   onClick: () => void;
// };

// interface EmptyStateCommandInterfaceProps {}

// const PromptButton = ({ icon, label, onClick }: SuggestedPrompt) => (
//   <button
//     onClick={onClick}
//     className="flex items-center gap-2 px-4 py-2 text-sm text-gray-600 bg-white rounded-lg
//                border border-gray-200 hover:bg-gray-50 transition-colors duration-200"
//   >
//     <span className="w-4 h-4">{icon}</span>
//     {label}
//   </button>
// );

export const EmptyStateCommandInterface = () => {
  const chatRef = useRef<HTMLDivElement>(null);
  const isVisible = true;
  // const suggestedPrompts: SuggestedPrompt[] = [
  //   {
  //     icon: (
  //       <svg
  //         className="w-4 h-4"
  //         viewBox="0 0 24 24"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <path
  //           d="M8 12H16M12 8V16"
  //           stroke="currentColor"
  //           strokeWidth="2"
  //           strokeLinecap="round"
  //         />
  //       </svg>
  //     ),
  //     label: 'AI Memory Management',
  //     onClick: () => console.debug('Create a new chart'),
  //   },
  //   {
  //     icon: (
  //       <svg
  //         className="w-4 h-4"
  //         viewBox="0 0 24 24"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <path
  //           d="M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V7C19 5.89543 18.1046 5 17 5H15"
  //           stroke="currentColor"
  //           strokeWidth="2"
  //         />
  //       </svg>
  //     ),
  //     label: 'Tool list',
  //     onClick: () => console.debug('Show tool list'),
  //   },
  //   {
  //     icon: (
  //       <svg
  //         className="w-4 h-4"
  //         viewBox="0 0 24 24"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <path
  //           d="M12 4V20M4 12H20"
  //           stroke="currentColor"
  //           strokeWidth="2"
  //           strokeLinecap="round"
  //         />
  //       </svg>
  //     ),
  //     label: 'App builder',
  //     onClick: () => console.debug('Open app builder'),
  //   },
  // ];

  return (
    <div
      className={cn(
        'w-full h-screen z-10 flex flex-col items-center justify-center gap-4 bg-neutral-100 transition-opacity duration-500 ease-in-out\n',
        isVisible ? 'opacity-100' : 'opacity-0',
      )}
    >
      {/* Empty state header */}
      <section className="text-center space-y-4">
        <h1 className="text-4xl font-bold text-gray-900">
          Welcome to Command Interface
        </h1>
        <p className="text-gray-600">
          Your AI assistant for telecom operations and support. How can I help
          you today?
        </p>
      </section>
      <div className="w-full max-w-3xl">
        <Input loading={false} chatRef={chatRef} />
      </div>
      <section className="flex flex-wrap justify-center gap-4 max-w-2xl px-4">
        {/*{suggestedPrompts.map(prompt => (*/}
        {/*  <PromptButton*/}
        {/*    key={prompt.label}*/}
        {/*    icon={prompt.icon}*/}
        {/*    label={prompt.label}*/}
        {/*    onClick={prompt.onClick}*/}
        {/*  />*/}
        {/*))}*/}
      </section>
    </div>
  );
};
