// @ts-nocheck
import { API_URL } from '@api/configs.ts';

export class CommandInterfaceClient {
  private readonly defaultHeaders: HeadersInit;

  constructor() {
    this.defaultHeaders = {
      authorization: localStorage.getItem('token') ?? '',
      'Content-Type': 'application/json',
    };
  }

  public async saveCommandInterfaceChat(payload: any): Promise<any> {
    try {
      return await this.fetchWithErrorHandling(`${API_URL}/command`, {
        method: 'POST',
        headers: this.defaultHeaders,
        body: JSON.stringify(payload),
      });
    } catch (error) {
      throw new Error(
        `Failed to save command interface chat: ${error.message}`,
      );
    }
  }

  public async getCommandInterfaceSessions(): Promise<any> {
    try {
      const res = await this.fetchWithErrorHandling(`${API_URL}/session/all`, {
        method: 'GET',
        headers: this.defaultHeaders,
      });
      return res || [];
    } catch (error) {
      throw new Error(
        `Failed to get command interface sessions: ${error.message}`,
      );
    }
  }

  private async fetchWithErrorHandling(url: string, options: RequestInit) {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  }
}
