import { memo, useRef } from 'react';
import { Button } from '@packages/ui/shadcn/ui/button.tsx';
import { ObjectivesTextArea } from '@components/ObjectivesArea/ObjectivesTextArea.tsx';
import { useObjectivesArea } from '@app/hooks/useAppDevelopment/useObjectivesArea.ts';
import { AnalysisAlert } from '@components/ObjectivesArea/AnalysisAlert.tsx';
import { ModelOptions, ProcessedLine, SetShapeDataFn } from '@app/types';
import { ObjectivesAnalysisArea } from '@components/ObjectivesArea/ObjectiveAnalysisArea.tsx';
import { SaveChangesButton } from '@components/ObjectivesArea/SaveChangesBtn.tsx';

interface ObjectivesTextAreaProps {
  objectives: string | undefined;
  analysis?: ProcessedLine[];
  saveChanges: SetShapeDataFn;
  _modelOptions?: ModelOptions;
}

function ObjectivesAreaContainer({
  objectives,
  analysis,
  saveChanges,
  _modelOptions,
}: ObjectivesTextAreaProps) {
  const spanRef = useRef<HTMLSpanElement>(null);
  const {
    text,
    redefine,
    handleAddSelectedKey,
    loadingKeyWords,
    emitObjectives,
    setText,
    processedObjectives,
    modelOptions,
  } = useObjectivesArea({
    objectives,
    analysis,
    _modelOptions,
  });

  const save = () => {
    saveChanges({
      objectives: text,
      analysis: processedObjectives,
      modelOptions: modelOptions,
    });
  };

  return (
    <div className="w-full flex flex-col gap-2">
      <ObjectivesTextArea spanRef={spanRef} text={text} setText={setText} />
      <AnalysisAlert
        loadingKeyWords={loadingKeyWords}
        runAnalysis={emitObjectives}
        show={text.length > 1}
      />
      <ObjectivesAnalysisArea
        handleAddSelectedKey={handleAddSelectedKey}
        processedObjectives={processedObjectives}
      />

      <section className="w-full flex items-center justify-between gap-8">
        <Button
          variant="outline"
          className="flex items-center gap-2 p-3 w-full self-end text-center rounded-lg bg-white border border-dark-300 text-xs font-medium max-w-[170px]"
          type="button"
          onClick={redefine}
          disabled={text.length === 0}
        >
          Reset Changes
        </Button>
        <SaveChangesButton onClick={save} />
      </section>
    </div>
  );
}

export const Container = memo(ObjectivesAreaContainer);
