import { ShapeColor } from '@app/types';

export class ShapesHelper {
  readonly shapeColors: ShapeColor[] = [
    {
      shape: 'circle',
      lightBg: 'rgb(134 239 172)',
      darkBg: 'rgb(74 222 128)',
      text: 'rgb(20 83 45)',
    },
    {
      shape: 'diamond',
      lightBg: 'rgb(253 186 116)',
      darkBg: 'rgb(251 146 60)',
      text: 'rgb(124 45 18)',
    },
    {
      shape: 'square',
      lightBg: 'rgb(216 180 254)',
      darkBg: 'rgb(192 132 252)',
      text: 'rgb(59 7 100)',
    },
    {
      shape: 'rectangle',
      lightBg: 'rgb(147 197 253)',
      darkBg: 'rgb(96 165 250)',
      text: 'rgb(30 58 138)',
    },
  ];

  public getShapeColors(shapeName: string): ShapeColor | undefined {
    return this.shapeColors.find(
      shape => shape.shape.toLowerCase() === shapeName.toLowerCase(),
    );
  }
}
