import { Fragment, memo, useState } from 'react';
import { cn } from '@packages/ui/shadcn/lib/utils.ts';
import { useObjectivesStore } from '@app/stores/useObjectivesStore.ts';
import { useShallow } from 'zustand/react/shallow';
import { LineBlock } from '@app/types';
import { ModelOptionsList } from '@components/ObjectivesArea/ModelOptionsList.tsx';
import { useOutsideClick } from '@app/hooks';

interface HighlightButtonProps {
  block: LineBlock;
  handleAddSelectedKey: (_: {
    currentWord: string;
    newWord: string;
    position: number;
  }) => void;
  lineIndex: number;
}

export const ObjectiveHighlightBtn = memo(
  ({ block, handleAddSelectedKey, lineIndex }: HighlightButtonProps) => {
    const [modelOpen, setModelOpen] = useState(false);
    const {
      modelOptions,
      setSelectedBlock,
      setOpenVarModal,
      openVarModal,
      selectedBlock,
    } = useObjectivesStore(
      useShallow(state => ({
        modelOptions: state.modelOptions,
        setSelectedBlock: state.setSelectedBlock,
        setOpenVarModal: state.setOpenVarModal,
        openVarModal: state.openVarModal,
        selectedBlock: state.selectedBlock,
      })),
    );

    const ref = useOutsideClick<HTMLButtonElement>(() => setModelOpen(false));
    const colors = {
      bg: block.type === 'model' ? '#FFF7ED' : '#EFF6FF',
      text: block.type === 'model' ? '#F97316' : '#3B82F6',
    };

    const handleClick = () => {
      setSelectedBlock({ ...block, line: lineIndex });
      if (block.type === 'model') {
        setOpenVarModal(false);
        setModelOpen(!modelOpen);
      } else {
        setModelOpen(false);
        setOpenVarModal(!openVarModal);
      }
    };

    return (
      <Fragment>
        <button
          ref={ref}
          className={cn(
            'z-20 px-1 rounded-md text-sm',
            block.value === selectedBlock?.value &&
              openVarModal &&
              'border border-gray-500',
            block.type === 'model' && 'relative',
          )}
          style={{
            background: colors.bg,
            color: colors.text,
          }}
          onClick={handleClick}
        >
          {block.value}
        </button>

        {block.type === 'model' && (
          <ModelOptionsList
            options={modelOptions}
            lineIndex={lineIndex}
            handleAddSelectedKey={handleAddSelectedKey}
            open={modelOpen}
            block={block}
          />
        )}
      </Fragment>
    );
  },
);
