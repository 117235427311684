import { AppSidebar } from './shadcn/app-sidebar.tsx';
import { SidebarProvider } from './shadcn/ui/sidebar.tsx';
import { ReactNode } from 'react';
import { ContextBreadcrumb } from './ContextBreadcrumb.tsx';

interface SideBarProps {
  children: ReactNode;
  username: string;
}

export default function Sidebar({ children, username }: SideBarProps) {
  return (
    <SidebarProvider>
      <AppSidebar />
      <main className="w-screen h-screen flex flex-col">
        <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12 bg-neutral-100 absolute top-10">
          <div className="flex items-center gap-2 px-4">
            <ContextBreadcrumb username={username} />
          </div>
        </header>
        <div className="flex flex-1">{children}</div>
      </main>
    </SidebarProvider>
  );
}
