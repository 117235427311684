import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AuthUser, validateToken } from '@hooks/utils/validateToken.ts';
import { API_URL, UserClient } from '@packages/api';

export interface AuthContextState {
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  logOut: () => void;
  currentUser: AuthUser;
  setCurrentUser: (user: AuthUser) => void;
  loadingTokenUser: boolean;
  startSessionWs: (userId: string) => void;
  wsRef: WebSocket | null;
}

export const AuthContext = createContext<AuthContextState>({
  isAuthenticated: false,
  setIsAuthenticated: () => null,
  logOut: () => null,
  currentUser: null,
  setCurrentUser: () => {},
  loadingTokenUser: false,
  startSessionWs: _ => {},
  wsRef: null,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<AuthUser>(null);
  const [loadingTokenUser, setLoadingTokenUser] = useState<boolean>(false);
  const wsRef = useRef<WebSocket | null>(null);

  const userApi = new UserClient();

  useEffect(() => {
    const token = localStorage.getItem('token');

    (async () => {
      const timer = Date.now();
      const data = await validateToken({
        setLoadingTokenUser,
        token,
      });

      if (!data || !token) {
        localStorage.removeItem('token');
        setLoadingTokenUser(false);
        setIsAuthenticated(false);
        return;
      }

      setCurrentUser({
        name: data.username,
        isAdmin: data.isAdmin,
        sessionToken: token,
        owner: data.owner!.id,
        id: data.id!,
      });

      // Calculate time difference
      const timeDiff = Date.now() - timer;
      const minimumTime = 1500;

      if (timeDiff >= minimumTime) {
        setLoadingTokenUser(false);
        setIsAuthenticated(true);
      } else {
        // wait 1s
        await new Promise(resolve =>
          setTimeout(resolve, minimumTime - timeDiff),
        );
        setLoadingTokenUser(false);
        setIsAuthenticated(true);
      }

      // start WS for session
      startSessionWs(data.id!);
    })();
  }, []);

  const logOut = () => {
    userApi
      .logOut(currentUser!.id, localStorage.getItem('token')!)
      .then(res => console.debug(res));
    setIsAuthenticated(false);
    wsRef.current?.close();
    setTimeout(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('x-user-session');
      localStorage.removeItem('owner');
    }, 2000);
  };

  function startSessionWs(userId: string) {
    if (!userId) return;
    const ws = new WebSocket(`${API_URL}?user=${userId}`);

    ws.onopen = () => {
      console.log('Connected to the Session server!');
    };

    ws.onclose = () => {
      console.log('Disconnected from the session server!');
      localStorage.removeItem('x-user-session');
    };

    wsRef.current = ws;
  }

  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      logOut,
      currentUser,
      setCurrentUser,
      loadingTokenUser,
      startSessionWs,
      wsRef: wsRef.current,
    }),
    [isAuthenticated, currentUser, loadingTokenUser, setCurrentUser, wsRef],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
