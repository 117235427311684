import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@ui/sidebar/shadcn/ui/breadcrumb.tsx';
import { useContextLocation } from '@packages/hooks';
import { SidebarTrigger } from '@ui/sidebar/shadcn/ui/sidebar.tsx';
import { Separator } from '@ui/sidebar/shadcn/ui/separator.tsx';
import { Fragment } from 'react';

export function ContextBreadcrumb({ username }: { username: string }) {
  const location = useContextLocation(username);

  if (
    location.pathname.includes('flowchart') ||
    location.pathname.includes('code') ||
    location.pathname.includes('commandInterface')
  ) {
    return null;
  }

  return (
    <Fragment>
      <SidebarTrigger className="-ml-1" />
      <Separator orientation="vertical" className="mr-2 h-4" />
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem className="hidden md:block">
            <BreadcrumbLink href="#">{location.currentLocation}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator className="hidden md:block" />
          <BreadcrumbItem>
            <BreadcrumbPage></BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
    </Fragment>
  );
}
