import { Fragment, memo, useMemo, useState } from 'react';
import { ObjectivesHighlightLine } from '@components/ObjectivesArea/ObjectivesHighlightLine.tsx';
import { cn } from '@app/lib/utils.ts';
import { ProcessedLine } from '@app/types';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { VarOptionsList } from '@components/ObjectivesArea/VarOptionsList.tsx';

interface HandleAddSelectKey {
  currentWord: string;
  newWord: string;
  position: number;
}

export const ObjectivesAnalysisArea = memo(
  ({
    handleAddSelectedKey,
    processedObjectives,
  }: {
    handleAddSelectedKey: (_: HandleAddSelectKey) => void;
    processedObjectives: ProcessedLine[];
  }) => {
    const [isOpen, setIsOpen] = useState(processedObjectives.length > 0);
    const hasAnalysis = useMemo(() => {
      if (processedObjectives.length > 0) {
        setIsOpen(true);
      }
      return processedObjectives.length > 0;
    }, [processedObjectives]);

    function switchOpenState() {
      setIsOpen(prev => !prev);
    }

    const contentMemo = useMemo(() => {
      if (!isOpen) {
        return null;
      }

      if (!hasAnalysis) {
        return (
          <section className="w-full h-full flex">
            <span className="text-sm m-auto">
              Run Analysis to see Naavik changes.
            </span>
          </section>
        );
      }

      return processedObjectives.map((line, index) => (
        <Fragment key={index}>
          <ObjectivesHighlightLine
            line={line}
            lineIndex={index}
            handleAddSelectedKey={handleAddSelectedKey}
          />
          <br />
        </Fragment>
      ));
    }, [isOpen, hasAnalysis, processedObjectives]);

    return (
      <div
        className={cn(
          'w-full max-h-2xl relative rounded-md p-4 pt-10 text-sm overflow-scroll',
          hasAnalysis ? 'bg-neutral-200' : 'bg-transparent border',
          isOpen ? 'min-h-72 h-full' : 'h-12',
        )}
      >
        <VarOptionsList handleAddSelectedKey={handleAddSelectedKey} />
        <span className="absolute text-xs text-gray-500 top-3 left-4 z-20">
          Naavik Analysis
        </span>
        <button
          className="flex items-center justify-between cursor-pointer absolute right-4 top-3"
          onClick={switchOpenState}
        >
          {isOpen ? (
            <ChevronUp className="w-4 h-4 text-gray-500" />
          ) : (
            <ChevronDown className="w-4 h-4 text-gray-500" />
          )}
        </button>
        {contentMemo}
      </div>
    );
  },
);
