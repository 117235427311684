import { Button } from '@packages/ui/shadcn/ui/button.tsx';
import { LoadingSpinner } from '@rangpt/components/ui/loadingSpinner.tsx';
import React, { useEffect, useRef, useState } from 'react';
import useSocketStore from '@rangpt/stores/useSocketStore.ts';
import { PauseCircleIcon, SendIcon } from 'lucide-react';
import { useCommandInterface } from '@rangpt/hooks';

interface ChatInputProps {
  loading: boolean;
  isBlocked?: boolean;
  chatRef: React.RefObject<HTMLDivElement>;
}

const Input = ({ loading, isBlocked, chatRef }: ChatInputProps) => {
  const { prompt, chat, pausePromptProcess } = useCommandInterface();
  const { config } = useSocketStore();
  const [promptMessage, setPromptMessage] = useState('');
  const [showPause, setShowPause] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const nextPrompt = chat.length + 1;
  let inputPlaceholder = `Prompt ${nextPrompt}: `;

  if (loading) {
    inputPlaceholder = `Prompt ${nextPrompt - 1}: Generating...`;
  }
  if (isBlocked) {
    inputPlaceholder += 'Awaiting prompt feedback';
  }
  if (!loading && !isBlocked) {
    if (chat.length === 0) {
      inputPlaceholder = 'Message Command Interface...';
    } else {
      inputPlaceholder +=
        config.type === 'query'
          ? 'Type a query prompt'
          : 'Type a control prompt';
    }
  }

  const write = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPromptMessage(e.target.value);
  };

  const submit = () => {
    if (loading && showPause) {
      console.debug('pause');
      pausePromptProcess();
      setPromptMessage('');
      return;
    }

    if (loading || isBlocked) return;

    prompt({ blob: undefined, blobType: undefined, message: promptMessage });
    setPromptMessage('');
  };

  const handleSubmitKey = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!event.shiftKey && event.key == 'Enter') {
      if (inputRef.current) {
        inputRef.current.style.height = '0';
        inputRef.current.style.height = `54px`;
      }
      event.preventDefault();
      submit();
    }
  };

  const submitButtonIcon = () => {
    if (loading && showPause) {
      return <PauseCircleIcon width={20} height={20} />;
    }
    if (loading) {
      return <LoadingSpinner />;
    }
    return <SendIcon color="white" width={20} height={20} />;
  };

  useEffect(() => {
    const handleTextareaChange = () => {
      if (inputRef.current) {
        inputRef.current.style.height = '0';
        inputRef.current.style.height = `${
          inputRef.current.scrollHeight + 2
        }px`;

        if (inputRef.current.clientHeight >= 300) {
          inputRef.current.style.overflow = 'auto';
        } else {
          inputRef.current.style.overflow = 'hidden';
        }
      }
    };

    if (chatRef?.current && containerRef?.current) {
      chatRef.current.addEventListener('mouseover', () => {
        setShowPause(true);
      });
      chatRef.current.addEventListener('mouseout', () => {
        setShowPause(false);
      });

      containerRef.current.addEventListener('mouseover', () => {
        setShowPause(true);
      });
      containerRef.current.addEventListener('mouseout', () => {
        setShowPause(false);
      });
    }

    if (inputRef.current) {
      inputRef.current.addEventListener('input', handleTextareaChange);

      // Trigger change on mount
      handleTextareaChange();
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('input', handleTextareaChange);
      }
    };
  }, []);

  return (
    <div
      className="relative w-full flex flex-col items-center p-4 shadow-top-sm"
      ref={containerRef}
    >
      <form
        className="w-full rounded-xl flex gap-2"
        onSubmit={e => e.preventDefault()}
      >
        <section className="w-full h-full pl-4 bg-white flex flex-col justify-end border border-gray-300 rounded-xl color-black overflow-hidden ease-resize">
          <section className="w-full h-full flex overflow-hidden">
            <textarea
              ref={inputRef}
              placeholder={inputPlaceholder}
              className="py-4 w-full max-w-full h-12 bg-white color-black outline-none text-sm word-break resize-none ease-resize"
              value={promptMessage}
              onChange={write}
              onKeyDown={handleSubmitKey}
            />
          </section>
        </section>
        <Button
          type="submit"
          variant="ghost"
          className="bg-[#E12026] rounded-xl px-5 focus-visible:ring-0 focus-visible:outline-none focus-visible:ring-transparent"
          onClick={submit}
          disabled={(loading && !showPause) ?? loading ?? isBlocked}
        >
          {submitButtonIcon()}
        </Button>
      </form>
    </div>
  );
};

export { Input };
