import { memo } from 'react';
import { ProcessedLine } from '@app/types';
import { cn } from '@app/lib/utils.ts';
import { ObjectiveHighlightBtn } from '@components/ObjectivesArea/ObjectiveHighlightBtn';

interface ObjectivesHighlightLineProps {
  line: ProcessedLine;
  handleAddSelectedKey: (_: {
    currentWord: string;
    newWord: string;
    position: number;
  }) => void;
  lineIndex: number;
}

export const ObjectivesHighlightLine = memo(
  ({ line, handleAddSelectedKey, lineIndex }: ObjectivesHighlightLineProps) => {
    return (
      <span>
        {line.map(block => {
          if (block.type === 'normal') {
            return (
              <span key={block.value} className="z-10">
                {block.value}
              </span>
            );
          }

          // Highlight block
          return (
            <span
              key={block.value}
              className={cn(block.type === 'model' && 'relative')}
            >
              <ObjectiveHighlightBtn
                block={block}
                handleAddSelectedKey={handleAddSelectedKey}
                lineIndex={lineIndex}
              />
            </span>
          );
        })}
      </span>
    );
  },
);
