import { PlayIcon } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@packages/ui/shadcn/ui/tooltip.tsx';
import GeneratingCodeGif from '@public/load-code.gif';
import { memo } from 'react';

interface AnalysisAlertProps {
  runAnalysis: (textAreaValue?: string) => void;
  loadingKeyWords: boolean;
  show: boolean;
}

export const AnalysisAlert = memo(
  ({ runAnalysis, loadingKeyWords, show }: AnalysisAlertProps) => {
    if (!show) return null;

    const onClick = () => runAnalysis();

    const mainText = loadingKeyWords
      ? 'Analyzing key phrases...'
      : 'Run analysis and get key phrases options.';
    const toolTipText = loadingKeyWords ? 'Running' : 'Run objectives analysis';

    return (
      <button
        className="w-full h-12 p-4 rounded-lg border border-dark-1 text-xs flex gap-2 items-center justify-start text-dark-1 fade-in cursor-pointer"
        onClick={onClick}
        disabled={loadingKeyWords}
      >
        <TooltipProvider>
          <Tooltip delayDuration={500}>
            <TooltipTrigger asChild>
              {loadingKeyWords ? (
                <img
                  src={GeneratingCodeGif}
                  alt="loading key phrases"
                  className="h-6 w-6 ml-2 mt-1"
                />
              ) : (
                <PlayIcon className="h-4 w-4 text-black" />
              )}
            </TooltipTrigger>
            <TooltipContent className="text-gray-500 text-xs mr-2">
              {toolTipText}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <span className="m-auto">{mainText}</span>
      </button>
    );
  },
);
