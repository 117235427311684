import { useRoutines } from '@app/hooks';
import { Options } from '@components/home/Options.tsx';
import { HomeComponents } from '@app/components/home';
import { PlusSquare } from 'react-feather';
import { SearchRoutine } from '@components/home/SearchRoutine.tsx';
import { useState } from 'react';

export const Home = () => {
  const {
    routines,
    createNewRoutine,
    changeRoutineName,
    selectRoutine,
    deleteRoutine,
    updateRoutine,
    createRoutine,
    currentRoutine,
  } = useRoutines();

  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div className="w-full h-full flex flex-col items-start justify-start gap-12 p-8 pl-12 pt-24 bg-neutral-100">
      <section className="w-full flex items-center justify-between">
        <SearchRoutine searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <HomeComponents.CreateRoutineModal
          onConfirm={createNewRoutine}
          loading={createRoutine.isPending}
        >
          <button className="w-fit bg-white flex gap-3 items-center p-3 text-sm outline-none z-10 rounded-lg shadow-lg">
            New Routine
            <PlusSquare className="w-4 h-4" />
          </button>
        </HomeComponents.CreateRoutineModal>
      </section>
      {/* Routine List */}
      <section className="flex gap-2 flex-wrap">
        {routines.map(routine => {
          const routineName = routine.name.toLowerCase();

          if (
            searchTerm.length > 0 &&
            !routineName.includes(searchTerm.toLowerCase())
          ) {
            return null;
          }
          return (
            <HomeComponents.Routine
              key={routine.id}
              id={routine.id}
              name={routine.name}
              lastUpdate={routine.updatedAt}
              selectRoutine={selectRoutine}
              isCurrentRoutine={routine.id === currentRoutine.id}
            >
              <Options
                id={routine.id}
                name={routine.name}
                selectRoutine={selectRoutine}
                changeRoutineName={changeRoutineName}
                deleteRoutine={deleteRoutine}
                loading={updateRoutine.isPending}
              />
            </HomeComponents.Routine>
          );
        })}
      </section>
    </div>
  );
};
