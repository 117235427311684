import { API_URL } from '../configs.ts';
import { token } from '@packages/api';
import {
  CreateUserPayload,
  CreateUserRes,
  LoginRes,
  UpdateUserPayload,
  User,
} from '@api/types';
import { Chart } from '@api/utils.ts';

export class UserClient {
  public usersActives: number;
  public defaultHeaders: Record<string, string>;

  constructor(usersActives?: number, userToken?: string) {
    this.usersActives = usersActives ?? 0;
    this.defaultHeaders = {
      authorization: userToken ?? token,
      'Content-Type': 'application/json',
      'x-user-session': localStorage.getItem('x-user-session') || '',
    };
  }

  public async login(payload: { username: string; password: string }) {
    return await this.fetchWithErrorHandling<LoginRes>(`${API_URL}/login`, {
      method: 'POST',
      headers: this.defaultHeaders,
      body: JSON.stringify(payload),
    });
  }

  public async findUser(id: string) {
    return await this.fetchWithErrorHandling<User>(`${API_URL}/user/${id}`, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  }

  public async create(payload: CreateUserPayload) {
    return await this.fetchWithErrorHandling<CreateUserRes>(`${API_URL}/user`, {
      method: 'POST',
      headers: this.defaultHeaders,
      body: JSON.stringify({
        ...payload,
        owner: localStorage.getItem('owner'),
        isActive: this.usersActives < 5,
      }),
    });
  }

  public async update(payload: UpdateUserPayload) {
    return fetch(`${API_URL}/user`, {
      headers: this.defaultHeaders,
      method: 'PATCH',
      body: JSON.stringify({
        ...payload,
        owner: localStorage.getItem('owner'),
      }),
    }).then(res => {
      if (res.ok) {
        return payload;
      }

      throw new Error('Failed to update user!');
    });
  }

  public async listUsers(owner?: string) {
    if (!owner) return null;

    return await this.fetchWithErrorHandling<User[]>(
      `${API_URL}/user/list/${owner}`,
      {
        method: 'GET',
        headers: this.defaultHeaders,
      },
    );
  }

  public async getDashboard(username: string) {
    return await this.fetchWithErrorHandling<Chart[]>(
      `${API_URL}/user/charts/${username}`,
      {
        method: 'GET',
        headers: this.defaultHeaders,
      },
    );
  }

  public async logOut(id: string, token: string) {
    return await this.fetchWithErrorHandling<Chart[]>(
      `${API_URL}/user/logout/${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token.trim().replace(/,/g, ''),
        },
      },
    );
  }

  private async fetchWithErrorHandling<T>(url: string, options: RequestInit) {
    const response = await fetch(url, options);
    if (!response.ok) {
      const res = await response.json();
      throw new Error(res.error);

      // if (response.status === 401) {
      // }
      //
      // if (response.status === 409) {
      //   throw new Error('Deactivated user!');
      // }
      //
      // if (response.status === 204) {
      //   throw new Error('No data found!');
      // }
      //
      // throw new Error(
      //   `HTTP error! status: ${response.status}, message: ${response.statusText}`,
      // );
    }

    return (await response.json()) as T;
  }
}
