import { jwtDecode } from 'jwt-decode';
import { User, UserClient } from '@packages/api';
import { Dispatch, SetStateAction } from 'react';

export type AuthUser = {
  id: string;
  name: string;
  sessionToken: string;
  isAdmin: boolean;
  owner: string;
} | null;

export interface JWTPayload {
  owner: string;
  id: string;
  exp: number;
}

interface ValidateTokenProps {
  setLoadingTokenUser: Dispatch<SetStateAction<boolean>>;
  token: string | null;
}

export async function validateToken({
  setLoadingTokenUser,
  token,
}: ValidateTokenProps): Promise<User | null> {
  const userApi = new UserClient();

  if (token) {
    setLoadingTokenUser(true);
    console.debug('~ Auth Context ~ token found');

    const decodedToken = jwtDecode<JWTPayload>(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      console.debug('token is expired');
      return null;
    }

    try {
      return await userApi.findUser(decodedToken.id);
    } catch (e) {
      return null;
    }
  }

  return null;
}
