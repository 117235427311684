import useDashboard from '@rangpt/hooks/useDashboard.ts';
import { ChartList } from '@rangpt/components/dashboard/chartsList.tsx';
import { Link } from '@tanstack/react-router';
import { WelcomeIcon } from '@rangpt/components/ui/welcomeIcon.tsx';
import { FadeIconLoading } from '@packages/ui';

export const MyDashboard = () => {
  const { dashboard, deleteGraph, isLoading } = useDashboard();

  if (isLoading) {
    return <FadeIconLoading text="Loading your Charts..." />;
  }

  if (dashboard.length === 0) {
    return (
      <div className="w-full h-full p-12 flex flex-col items-center justify-center min-w-3xl bg-neutral-100">
        <WelcomeIcon />
        <h1 className="mt-3 text-2xl text font-semibold leading-10">
          Let&apos;s Create Your First Chart!
        </h1>
        <p className="text-gray-500 text-center mt-2 text-xs">
          Start visualizing your data with a click. It&apos;s simple, fast and
          insightful.
          <br />
        </p>
        <Link
          to="/commandInterface"
          className="inline-flex items-center justify-center px-4 py-3 bg-white rounded-md text-xs font-medium ring-offset-dark-300 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 border border-black-2 mt-3 hover:bg-dark-300 hover:text-white shadow-md"
        >
          Generate a Chart
        </Link>
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col gap-8 items-start justify-center relative px-20 py-10 bg-neutral-200">
      {/*<section className="w-full p-2 flex items-center justify-between">*/}
      {/*  <h1 >Hello</h1>*/}
      {/*</section>*/}
      <ChartList dashboard={dashboard} deleteGraph={deleteGraph} />
    </div>
  );
};
