import { FormEvent, useEffect } from 'react';
import { useAuth } from '@packages/hooks';
import { Navigate } from '@tanstack/react-router';
import {
  AnimatedText,
  AuthProgress,
  defaultToastErrorToastOptions,
  Login,
} from '@packages/ui';
import { LoginRes } from '@api/types';
import { useMutation } from '@tanstack/react-query';
import { UserClient } from '@api/client';
import { flowChartStore } from '@app/stores';
import { useShallow } from 'zustand/react/shallow';
import { useRoutinesStore } from '@app/stores/routine.store.ts';
import { Button } from '@shadcn/ui/button.tsx';
import { HelpCircle } from 'react-feather';
import useDashboardStore from '@rangpt/stores/useDashboardStore.ts';
import useCommandInterfaceStore from '@rangpt/stores/useCommandInterfaceStore.ts';
import { toast } from 'react-toastify';

export const LoginPage = () => {
  const {
    isAuthenticated,
    setIsAuthenticated,
    setCurrentUser,
    loadingTokenUser,
    startSessionWs,
  } = useAuth();
  const { resetsTore: resetFlowChart } = flowChartStore(
    useShallow(state => ({
      resetsTore: state.resetStore,
    })),
  );
  const { resetsTore: resetRoutines } = useRoutinesStore(
    useShallow(state => ({
      resetsTore: state.resetStore,
    })),
  );
  const { resetsTore: resetDashboard } = useDashboardStore(
    useShallow(state => ({
      resetsTore: state.resetStore,
    })),
  );

  const { resetsTore: resetChat } = useCommandInterfaceStore(
    useShallow(state => ({
      resetsTore: state.resetStore,
    })),
  );

  useEffect(() => {
    resetRoutines();
    resetFlowChart();
    resetDashboard();
    resetChat();
  }, []);

  const userAPI = new UserClient();

  const loginMutation = useMutation({
    mutationFn: ({
      username,
      password,
    }: {
      username: string;
      password: string;
    }) => userAPI.login({ username, password }),
    onSuccess: (data: LoginRes) => {
      if (data.owner) {
        localStorage.setItem('owner', data.owner);
      } else {
        localStorage.removeItem('owner');
      }
      if (data.token) {
        localStorage.removeItem('token');
        localStorage.setItem('token', data.token);
      }

      localStorage.removeItem('x-user-session');
      localStorage.setItem('x-user-session', data.session);

      // @TODO: maybe add data validation here, as this state needs all of this info
      setCurrentUser({
        name: data.user,
        isAdmin: data.isAdmin,
        sessionToken: data.token,
        owner: data.owner ?? '',
        id: data.id,
      });
      startSessionWs(data.id);
      setTimeout(() => {
        setIsAuthenticated(true);
      }, 500);
    },
    onError: error => {
      console.debug(error);
      toast.error(error.message, defaultToastErrorToastOptions);
    },
  });

  if (loadingTokenUser) {
    return <AuthProgress />;
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData) as { user: string; pass: string };

    loginMutation.mutate({ username: data.user.trim(), password: data.pass });
  };

  return (
    <div className="w-screen h-screen flex bg-neutral-100 p-4">
      <section className="flex flex-col items-center justify-center gap-12 h-full w-1/2 rounded-2xl xl:p-2 p-4 opacity-80 shadow-lg bg-white relative">
        <img
          src="/aira-main-logo.png"
          alt="Aira entry speech"
          className=" w-1/5"
        />

        <img
          src="/undraw_visionary_technology.svg"
          alt="Aira entry speech"
          className=" w-2/4"
        />

        <section className="max-w-[580px] w-full flex flex-col gap-6 items-center text-center">
          <h1 className="text-dark-1 text-2xl font-bold">
            Naavik is reimagining what telecom can be in a software-driven
            world.
          </h1>
          <span className="text-gray-600 text-sm">
            Aira Naavik is the first platform to seamlessly combine GenAI with
            traditional AI to solve impactful use cases for network operators.
          </span>
        </section>
        <div className="flex gap-2 absolute bottom-2">
          <span className="w-4 h-2 cursor-pointer rounded-xl bg-red-400"></span>
          <span className="w-4 h-2 cursor-pointer rounded-xl bg-gray-300"></span>
          <span className="w-4 h-2 cursor-pointer rounded-xl bg-gray-300"></span>
          <span className="w-4 h-2 cursor-pointer rounded-xl bg-gray-300"></span>
        </div>
      </section>
      <section className="h-full w-1/2 flex flex-col items-center">
        <Button variant="ghost" className="self-end">
          <HelpCircle className="w-5 h-5" />
        </Button>

        <section className="w-1/2 my-auto flex flex-col items-center gap-20">
          <AnimatedText
            text="Welcome to Naavik."
            className="text-dark-1 text-2xl font-medium"
          />
          <Login.Form
            submit={submit}
            error={loginMutation.error}
            isLoading={loginMutation.isPending}
          />
        </section>

        <a href="#" className="text-dark-300 text-xs hover:underline self-end">
          Contact us
        </a>
      </section>
    </div>
  );
};
